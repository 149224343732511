import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Avatar,
  VStack,
  HStack,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";

import { useUserAuth } from "../context/UserAuthProvider";
import Conversation from "./Conversation";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_DEALS = "/deals";
const ENDPOINT_USERS = "/users";

export default function Deal() {
  const { deal_id } = useParams();
  const location = useLocation();
  const { meritoSignedIn, firebaseBuildAuthHeader } = useUserAuth();

  const [deal, setDeal] = useState(null);
  const [isLoadingDeal, setIsLoadingDeal] = useState(true);

  // Run when deal_id or location.state changes
  useEffect(() => {
    setIsLoadingDeal(true);

    // Check if deal data was passed via project page
    if (location.state && location.state.deal) {
      // Use the passed deal data if available
      setDeal(location.state.deal);
    } else {
      // If no deal data was passed, fetch it from the API
      const getDealData = async () => {
        try {
          console.log(`Calling Merito API: ${ENDPOINT_DEALS}`);
          const authHeader = await firebaseBuildAuthHeader();
          const response = await axios.get(
            `${MERITO_API_HOST}${ENDPOINT_DEALS}?deal_id=${deal_id}`,
            authHeader
          );

          if ("content" in response.data) {
            setDeal(response.data.content[0]);
            console.log("Deal retrieved:\n", response.data.content[0]);
            // TODO: Add Toast for success message
          } else {
            const errMsg = `Deal not found for deal_id: '${deal_id}'`;
            console.error(errMsg);
            // TODO: Add Toast for error message
          }
        } catch (err) {
          const errMsg = "Failed to retrieve deal data:\n";
          console.error(errMsg, err);
          // TODO: Add Toast for error message
        }
      };

      getDealData();
    }

    // Get usernames for the buyer and seller
    const getUserData = async () => {
      try {
        console.log(`Calling Merito API: ${ENDPOINT_USERS}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.get(
          `${MERITO_API_HOST}${ENDPOINT_USERS}?user_id=${deal.user_id_seller}&user_id=${deal.user_id_buyer}`,
          authHeader
        );

        if ("content" in response.data && response.data.content.length == 2) {
          let username_seller, username_buyer;
          for (let user of response.data.content) {
            if (user.user_id === deal.user_id_seller) {
              username_seller = user.username;
            } else {
              username_buyer = user.username;
            }
          }

          setDeal({
            ...deal,
            username_seller: username_seller,
            username_buyer: username_buyer,
          });
          console.log("Usernames retrieved:\n", [
            username_seller,
            username_buyer,
          ]);
          // TODO: Add Toast for success message
        } else {
          const errMsg = `User(s) not found for user_ids: ${[
            deal.user_id_seller,
            deal.user_id_buyer,
          ]}`;
          console.error(errMsg);
          // TODO: Add Toast for error message
        }
      } catch (err) {
        const errMsg = "Failed to retrieve usernames:\n";
        console.error(errMsg, err);
        // TODO: Add Toast for error message
      } finally {
        setIsLoadingDeal(false);
      }
    };

    getUserData();
  }, [deal_id, location.state]);

  return (
    <>
      {/* Ensure deal is loaded before rendering */}
      {!isLoadingDeal && deal ? (
        <Flex direction="row" align="center">
          <VStack align="center" spacing={12} flex={1}>
            <Text
              color="ScienceDeals.100"
              fontWeight={600}
              fontSize="4xl"
              pt={12}
            >
              Deal: {deal.deal_name}
            </Text>
            <VStack>
              <VStack
                as="span"
                color="ScienceDeals.300"
                fontWeight={500}
                fontSize="3xl"
                spacing={4}
                pl={{ base: 0, md: 8 }}
                align="start"
              >
                <Text>
                  Seller:{" "}
                  <Text as="span" color="ScienceDeals.200">
                    {deal.username_seller}
                  </Text>
                </Text>
                <Text>
                  Buyer:{" "}
                  <Text as="span" color="ScienceDeals.200">
                    {deal.username_buyer}
                  </Text>
                </Text>
                <Text>
                  Deal Status:{" "}
                  <Text as="span" color="ScienceDeals.200">
                    {deal.deal_status}
                  </Text>
                </Text>
              </VStack>
            </VStack>
            <Conversation deal_id={deal.deal_id} />
          </VStack>
        </Flex>
      ) : (
        <Text
          color="Neutral.500"
          fontSize={{ base: "xl", md: "2xl" }}
          mt={"30vh"}
        >
          Loading Deal Details...
        </Text>
      )}
    </>
  );
}
