import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Image,
  Text,
  Flex,
  Divider,
  VStack,
  Hide,
  Icon,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/UserAuthProvider";
import { PDFViewer } from "./PDFViewer";
import ProjectContactForm from "./ProjectContactForm";
import { formatNumber } from "../utilities/utilities";
import {
  projectDetailLoadFailed,
  projectNotFound,
  isLoadingProjectDetail,
} from "../utilities/messages";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_PROJECTS = "/science_deals/projects";

const FIELD_FOR_SIGNED_IN_USER = "project_cost";

const projectDurationList = {
  1: "~ 6 months",
  2: "6 months ~ 1 year",
  3: "1 year ~ 3 years",
  4: "3 years ~ 5 years",
  5: "5 years ~ 10 years",
  6: "10 years ~",
};

export default function ScienceDealsProject() {
  const { project_id } = useParams();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { i18n } = useTranslation();
  const { meritoSignedIn, firebaseBuildAuthHeader } = useUserAuth();

  const [project, setProject] = useState(null);
  const [isLoadingProject, setIsLoadingProject] = useState(true);

  const getProjectData = async () => {
    try {
      console.log(`Calling Merito API: ${ENDPOINT_PROJECTS}`);
      const authHeader = await firebaseBuildAuthHeader();
      const response = await axios.get(
        `${MERITO_API_HOST}${ENDPOINT_PROJECTS}?project_id=${project_id}`,
        authHeader
      );

      if (response.data.content.length > 0) {
        setProject(response.data.content[0]);
        console.log("Project retrieved:\n", response.data.content[0]);
      } else {
        const errMsg = `Project not found for project_id: '${project_id}'`;
        console.error(errMsg);
        toast({
          status: "error",
          isClosable: true,
          title: "Error",
          description: projectNotFound[i18n.language],
        });
      }
    } catch (err) {
      const errMsg = "Failed to retrieve project data:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: projectDetailLoadFailed[i18n.language],
      });
    } finally {
      // Data fetching is complete
      setIsLoadingProject(false);
    }
  };

  // TODO: start a new deal and conversation
  // const handleDealCreation = () => {
  //   // First open a popup modal to confirm the deal creation
  //   // If confirmed:
  //   // - Create a new deal for the project, buyer, and seller (API call)
  //   // - Once deal is created and the deal data is returned,
  //   // - Navigate to the deal page passing the deal data
  //   // - Use "navigate(`/deal/${deal.deal_id}`, { state: { deal } })",
  //        as users could directly access the deal page only with deal_id like the project page
  // };

  // Run when project_id or location.state changes
  useEffect(() => {
    setIsLoadingProject(true);

    // Check if project data was passed via listing page
    if (location.state && location.state.project) {
      // Use the passed project data if available
      setProject(location.state.project);
      setIsLoadingProject(false);
    } else {
      // If no project data was passed, fetch it from the API
      getProjectData();
    }
  }, [project_id, location.state]);

  useEffect(() => {
    if (meritoSignedIn && project && !(FIELD_FOR_SIGNED_IN_USER in project)) {
      // If the user is signed in and the project is filterd data, fetch the full project data
      getProjectData();
    }
  }, [meritoSignedIn, project]);

  return (
    <>
      {/* Ensure project is loaded before rendering */}
      {!isLoadingProject && project ? (
        <Flex w="90%" direction="column" my={12}>
          {/* Title */}
          <Flex w="full" direction="column" alignItems="left">
            <Text
              color="ScienceDeals.100"
              textAlign="center"
              whiteSpace="normal"
              overflowWrap="break-word"
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              mb={5}
              px={3}
            >
              {project.project_name}
            </Text>
            <Divider orientation="horizontal" />
          </Flex>

          {/* Project details, image */}
          <Flex
            w="full"
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="start"
            my={{ base: 10, md: 12 }}
          >
            <VStack
              w="full"
              h={{ base: "none", md: "60vh" }}
              alignItems="center"
              color="ScienceDeals.300"
              fontWeight={500}
              fontSize={{ base: "lg", md: "xl" }}
              spacing={3}
            >
              {/* Project image for smaller screen */}
              <Flex
                h="full"
                w="full"
                display={{ base: "block", md: "none" }}
                justifyContent="center"
                alignItems="center"
                px={5}
              >
                <Image
                  h="full"
                  w="full"
                  objectFit="contain"
                  src={project.project_image_url}
                  alt={project.project_name}
                  loading="lazy"
                  fallback={
                    <Icon
                      as={MdImageNotSupported}
                      boxSize={12}
                      color="Neutral.500"
                    />
                  }
                  borderRadius="md"
                  mb={4}
                />
              </Flex>

              {/* Project details */}
              <Container h="full" overflowY="scroll">
                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Owner:
                    <Text color="ScienceDeals.200">
                      {project.project_owner}
                    </Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Organization:
                    <Text color="ScienceDeals.200">{project.organization}</Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Jurisdiction:
                    <Text color="ScienceDeals.200">{project.jurisdiction}</Text>
                  </Text>
                </Box>

                <Box
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Description:
                    <Text color="ScienceDeals.200" whiteSpace="pre-wrap">
                      {project.project_description}
                    </Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Goal:
                    <Text color="ScienceDeals.200" whiteSpace="pre-wrap">
                      {project.project_goal}
                    </Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Start Date:
                    <Text color="ScienceDeals.200">
                      {project.project_start_date}
                    </Text>
                  </Text>
                </Box>

                <Box
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Duration:
                    <Text color="ScienceDeals.200">
                      {projectDurationList[project.project_duration]}
                    </Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={5}
                  py={4}
                >
                  <Text>
                    Project Cost:
                    <Text color="ScienceDeals.200">
                      {formatNumber(project.project_cost)}{" "}
                      {project.project_cost_currency}
                    </Text>
                  </Text>
                </Box>

                <Box
                  hidden={!project[FIELD_FOR_SIGNED_IN_USER] || !meritoSignedIn}
                  w="full"
                  role="group"
                  _hover={{ bg: "Neutral.900" }}
                  borderRadius="md"
                  px={4}
                  py={5}
                >
                  <PDFViewer
                    project_document_url={project.project_document_url}
                  />
                </Box>
              </Container>
            </VStack>

            {/* Project image for biger screen */}
            <Flex
              w="full"
              h="60vh"
              display={{ base: "none", md: "block" }}
              direction="column"
              justifyContent="start"
              alignItems="center"
            >
              {/* Project image */}
              <Image
                w="full"
                h="full"
                objectFit="contain"
                src={project.project_image_url}
                alt={project.project_name}
                loading="lazy"
                fallback={
                  <Icon
                    as={MdImageNotSupported}
                    boxSize={12}
                    color="gray.500"
                  />
                }
                borderRadius="md"
              />
            </Flex>
          </Flex>

          {/* Buttons */}
          <Flex
            w="full"
            direction={{ base: "column", md: "row" }}
            justifyContent="start"
            alignItems="center"
            gap={{ base: 4, md: 6 }}
          >
            <Divider
              display={{ base: "block", md: "none" }}
              orientation="horizontal"
              mb={4}
            />

            {/* Modal for contact form */}
            <Box>
              <Button
                w={{ base: "190px", md: "200px" }}
                variant="ScienceDeals"
                fontSize={{ base: "lg", md: "xl" }}
                px={5}
                py={4}
                onClick={onOpen}
              >
                Express Interest
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(9px)" />
                <ModalContent boxShadow="2xl" mx={4} my={36}>
                  <ModalCloseButton />
                  <ProjectContactForm project_name={project.project_name} />
                </ModalContent>
              </Modal>
            </Box>

            {/* Start Deal and Conversation */}
            {/* * The button should be disabled if the user is not signed in, or if the user is the project owner */}
            {/* <Button
              w={{ base: "190px", md: "200px" }}
              variant="ScienceDeals"
              fontSize={{ base: "lg", md: "xl" }}
              px={5}
              py={4}
              onClick={handleDealCreation}
            >
              Start Deal
            </Button> */}

            <Divider
              display={{ base: "none", md: "block" }}
              orientation="horizontal"
            />
          </Flex>
        </Flex>
      ) : (
        <Text
          color="Neutral.500"
          fontSize={{ base: "xl", md: "2xl" }}
          mt={"30vh"}
        >
          {isLoadingProjectDetail[i18n.language]}
        </Text>
      )}
    </>
  );
}
