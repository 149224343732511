import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Icon,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

import { parseHTMLContent } from "../utilities/utilities";
import {
  MERITO_PLATFORM_AGREEMENT_EN,
  MERITO_PLATFORM_AGREEMENT_JP,
} from "../utilities/MeritoPlatformAgreement";

export default function DisclaimerModal({ onClose, handleProjectCreation }) {
  const { t, i18n } = useTranslation("project_creation");

  return (
    <Box
      className={i18n.language === "jp" ? "jp" : "en"}
      bg="Neutral.0"
      borderRadius="xl"
      p={{ base: 6, md: 10 }}
    >
      {/* Disclaimer text */}
      <ModalBody>
        <Flex
          alignItems="center"
          color="Secondary.700"
          fontSize={{ base: "lg", md: "xl" }}
          mt={2}
        >
          <Icon w={7} h={7} as={RiErrorWarningFill} mr={3} />
          <Text fontWeight={400}>{t("disclaimer_title")}</Text>
        </Flex>
        <Box
          maxH="360px"
          overflowY="scroll"
          borderWidth="1px"
          borderColor="Neutral.100"
          borderRadius="md"
          mt={5}
          mb={2}
          px={6}
          py={4}
        >
          <Text
            color="Neutral.800"
            fontSize={{ base: "sm", md: "md" }}
            fontWeight={300}
          >
            {i18n.language === "jp"
              ? parseHTMLContent(MERITO_PLATFORM_AGREEMENT_JP)
              : parseHTMLContent(MERITO_PLATFORM_AGREEMENT_EN)}
          </Text>
        </Box>
      </ModalBody>

      {/* Buttons */}
      <ModalFooter>
        <Flex
          w="full"
          direction={{ base: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
          gap={6}
        >
          <Button
            w={{ base: "210px", md: "230px" }}
            bg="Neutral.100"
            color="Neutral.600"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight={400}
            _hover={{
              bg: "Neutral.100",
              color: "Neutral.800",
              boxShadow: "lg",
            }}
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
          <Button
            w={{ base: "210px", md: "230px" }}
            bg="ScienceDeals.500"
            color="Neutral.50"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight={400}
            _hover={{
              bg: "ScienceDeals.600",
              color: "Neutral.100",
              boxShadow: "lg",
            }}
            onClick={async () => {
              onClose();
              await handleProjectCreation();
            }}
          >
            {t("confirm_and_proceed")}
          </Button>
        </Flex>
      </ModalFooter>
    </Box>
  );
}
