import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Form } from "react-router-dom";
import {
  Flex,
  Box,
  Input,
  Button,
  Text,
  Textarea,
  Select,
  Stack,
  VStack,
  HStack,
  Divider,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";
import {
  parseNumber,
  formatNumber,
  parseNumberIntoInteger,
  trimSpaces,
  isValidEmail,
  isValidPhoneNumber,
} from "../utilities/utilities";
import {
  emptyFieldError,
  invalidEmailError,
  invalidPhoneNumberError,
  formIncompleteMessage,
  fillAll3FieldsMessage,
  SRAGenerated,
  SRAGenerationFailed,
} from "../utilities/messages";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_SRA_GENERATION = "/science_deals/sra";

const SRA_FIELD_LIST = [
  "researcher_name",
  "sponsor_name",
  "research_purpose",
  "research_scope",
  "principal_investigator",
  "research_cooperators",
  "research_department",
  "project_term",
  "first_right_to_license",
  "license_type",
  "research_sites",
  "research_expenses_currency",
  "research_expenses_direct_expenses",
  "research_expenses_travel_expenses",
  "research_expenses_labor_fees",
  "research_expenses_indirect_expenses",
  "research_expenses_total_cost",
  "payment_terms",
  "project_milestones",
  "payment_due_date_1st",
  "payment_due_date_2nd",
  "payment_due_date_3rd",
  "project_equipment_used",
  "project_received_equipment_from_sponsor",
  "project_equipment_received",
  "other_sponsors",
  "funding_sources_for_equipment",
  "research_report_due_in_months",
  "additional_provisions_from_sponsor",
  "additional_provisions_from_lab",
  "administrative_contact_name",
  "administrative_contact_title",
  "administrative_contact_mailing_address",
  "administrative_contact_email",
  "administrative_contact_phone_number",
  "project_id",
];

export default function ScienceDealsSRAGeneration() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { t, i18n } = useTranslation("sra_generation");
  const { setIsLoading } = useLoading();
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const optionalSections = {
    research_cooperators: [
      "research_cooperators_full_name",
      "research_cooperators_position",
      "research_cooperators_role",
    ],
    project_equipment_received: [
      "project_equipment_received_name",
      "project_equipment_received_type_or_model",
      "project_equipment_received_quantity",
    ],
  };

  const [SRAForm, setSRAForm] = useState({
    researcher_name: null,
    sponsor_name: null,
    research_purpose: null,
    research_scope: null,
    principal_investigator_full_name: null,
    principal_investigator_position: null,
    principal_investigator_role: null,
    // TODO: Update to take multiple research_cooperator
    research_cooperators_full_name: null,
    research_cooperators_position: null,
    research_cooperators_role: null,
    research_department: null,
    project_term: null,
    first_right_to_license: null,
    license_type: null,
    research_sites: null,
    research_expenses_currency: null,
    research_expenses_direct_expenses: null,
    research_expenses_travel_expenses: null,
    research_expenses_labor_fees: null,
    research_expenses_indirect_expenses: null,
    research_expenses_total_cost: null,
    payment_terms: null,
    project_milestones: null,
    payment_due_date_1st: null,
    payment_due_date_2nd: null,
    payment_due_date_3rd: null,
    project_equipment_used: null,
    project_received_equipment_from_sponsor: null,
    // TODO: Update to take multiple project_equipment_received
    project_equipment_received_name: null,
    project_equipment_received_type_or_model: null,
    project_equipment_received_quantity: null,
    other_sponsors: null,
    funding_sources_for_equipment: null,
    research_report_due_in_months: null,
    additional_provisions_from_sponsor: null,
    additional_provisions_from_lab: null,
    administrative_contact_name: null,
    administrative_contact_title: null,
    administrative_contact_mailing_address: null,
    administrative_contact_email: null,
    administrative_contact_phone_number: null,
    project_id: location.state?.project_id,
  });
  const [formattedNumbers, setFormattedNumbers] = useState({
    research_expenses_direct_expenses: null,
    research_expenses_travel_expenses: null,
    research_expenses_labor_fees: null,
    research_expenses_indirect_expenses: null,
    research_expenses_total_cost: null,
    project_equipment_received_quantity: null,
    research_report_due_in_months: null,
  });
  const [formErrorMessages, setFormErrorMessages] = useState(() => {
    // Same set of fields as SRAForm, but without some non-required fields
    const {
      sponsor_name,
      other_sponsors,
      funding_sources_for_equipment,
      additional_provisions_from_sponsor,
      additional_provisions_from_lab,
      project_id,
      ...rest
    } = SRAForm;
    return { ...rest };
  });

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  const handleInputChange = (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value } = event.target;

    if (
      [
        "research_expenses_direct_expenses",
        "research_expenses_travel_expenses",
        "research_expenses_labor_fees",
        "research_expenses_indirect_expenses",
        "research_expenses_total_cost",
        "project_equipment_received_quantity",
        "research_report_due_in_months",
      ].includes(name)
    ) {
      // Update both formatted and form values
      setFormattedNumbers({
        ...formattedNumbers,
        // Keep the raw value here (onChange) to let users type values like 1,000, 10.00, etc.
        [name]: value,
      });
      setSRAForm({ ...SRAForm, [name]: parseNumber(value) });
    } else {
      setSRAForm({ ...SRAForm, [name]: value });
    }
  };

  const setOptionalSectionErrorMessage = (name, input, section) => {
    // Initialize the flags
    let isAnyFieldFilled = false;
    let isAllFieldsFilled = true;

    // Get the current values
    const currentSRAForm = {
      ...SRAForm,
      [name]: input,
    };

    section.forEach((field) => {
      isAnyFieldFilled = isAnyFieldFilled || currentSRAForm[field];
      isAllFieldsFilled = isAllFieldsFilled && currentSRAForm[field];
    });

    // Set error messages based on the results
    const errorMessage =
      isAnyFieldFilled && !isAllFieldsFilled
        ? fillAll3FieldsMessage[i18n.language]
        : null;

    return errorMessage;
  };

  const handleInputValidation = (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value } = event.target;
    let input;

    if (optionalSections.research_cooperators.includes(name)) {
      input = trimSpaces(value);
      // Update the value in the form
      setSRAForm({ ...SRAForm, [name]: input });

      // Set error message if some but not all fields are filled
      // (all fields must be either empty or filled)
      const errorMessage = setOptionalSectionErrorMessage(
        name,
        input,
        optionalSections.research_cooperators
      );
      setFormErrorMessages({
        ...formErrorMessages,
        research_cooperators_full_name: errorMessage,
        research_cooperators_position: errorMessage,
        research_cooperators_role: errorMessage,
      });
      return;
    } else if (
      [
        "first_right_to_license",
        "license_type",
        "research_expenses_currency",
        "payment_terms",
        "payment_due_date_1st",
        "payment_due_date_2nd",
        "payment_due_date_3rd",
        "project_received_equipment_from_sponsor",
      ].includes(name)
    ) {
      input = value;
    } else if (
      [
        "research_expenses_direct_expenses",
        "research_expenses_travel_expenses",
        "research_expenses_labor_fees",
        "research_expenses_indirect_expenses",
        "research_expenses_total_cost",
      ].includes(name)
    ) {
      input = parseNumber(value);

      // Update formatted string
      setFormattedNumbers({
        ...formattedNumbers,
        [name]: formatNumber(input),
      });
    } else if (optionalSections.project_equipment_received.includes(name)) {
      input =
        name === "project_equipment_received_quantity"
          ? parseNumberIntoInteger(value)
          : trimSpaces(value);

      if (name === "project_equipment_received_quantity") {
        // Update formatted string
        setFormattedNumbers({
          ...formattedNumbers,
          [name]: formatNumber(input),
        });
      }
      // Update the value in the form
      setSRAForm({ ...SRAForm, [name]: input });

      // Set error message if some but not all fields are filled
      // (all fields must be either empty or filled)
      const errorMessage = setOptionalSectionErrorMessage(
        name,
        input,
        optionalSections.project_equipment_received
      );
      setFormErrorMessages({
        ...formErrorMessages,
        project_equipment_received_name: errorMessage,
        project_equipment_received_type_or_model: errorMessage,
        project_equipment_received_quantity: errorMessage,
      });
      return;
    } else if (name === "research_report_due_in_months") {
      input = parseNumberIntoInteger(value);

      // Update formatted string
      setFormattedNumbers({
        ...formattedNumbers,
        [name]: formatNumber(input),
      });
    } else if (name === "administrative_contact_email") {
      input = trimSpaces(value);
      let isInvalid = false;
      // Update the value in the form
      setSRAForm({ ...SRAForm, [name]: input });

      // Check if the email is valid
      if (input) {
        isInvalid = !isValidEmail(input);
      }

      // Set error message if the input is not valid or empty/null
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null || input == undefined
            ? emptyFieldError[i18n.language]
            : isInvalid
            ? invalidEmailError[i18n.language]
            : null,
      });
      return;
    } else if (name === "administrative_contact_phone_number") {
      input = trimSpaces(value);
      let isInvalid = false;
      // Update the value in the form
      setSRAForm({ ...SRAForm, [name]: input });

      // Check if the phone number is valid
      if (input) {
        isInvalid = !isValidPhoneNumber(input);
      }

      // Set error message if the input is not valid or empty/null
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null || input == undefined
            ? emptyFieldError[i18n.language]
            : isInvalid
            ? invalidPhoneNumberError[i18n.language]
            : null,
      });
      return;
    } else {
      input = trimSpaces(value);
    }

    // Update the value in the form
    setSRAForm({ ...SRAForm, [name]: input });

    // Set error message if the input is empty/null
    if (name in formErrorMessages) {
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null || input == undefined
            ? emptyFieldError[i18n.language]
            : null,
      });
    }
  };

  const checkFieldStatus = () => {
    // Copy the current error messages
    const newErrorStatus = { ...formErrorMessages };

    Object.entries(newErrorStatus).forEach(([field, message]) => {
      const value = SRAForm[field];

      // If the field already has an error message, skip the check
      if (message !== null) return;

      // Skip the optional sections
      if (
        optionalSections.research_cooperators.includes(field) ||
        optionalSections.project_equipment_received.includes(field)
      ) {
        return;
      }

      // Check if the value is null and update the error message
      newErrorStatus[field] =
        value === "" || value === null || value == undefined
          ? emptyFieldError[i18n.language]
          : null;
    });

    // Set the updated error messages after the iteration
    setFormErrorMessages(newErrorStatus);

    // Return true if there are still error messages
    const hasIncompleteFields = Object.values(newErrorStatus).some(
      (message) => message !== null
    );
    return hasIncompleteFields;
  };

  const handleSRAGeneration = async (event) => {
    checkSignInStatus();
    event.preventDefault();
    setIsLoading(true);

    // First check if any fields still have error messages or are empty/null
    const hasIncompleteFields = checkFieldStatus();
    if (hasIncompleteFields) {
      setIsLoading(false);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: formIncompleteMessage[i18n.language],
      });
      return;
    }

    try {
      const sraFormData = {};
      for (const field of SRA_FIELD_LIST) {
        if (field === "principal_investigator") {
          sraFormData[field] = {
            full_name: SRAForm.principal_investigator_full_name,
            position: SRAForm.principal_investigator_position,
            role: SRAForm.principal_investigator_role,
          };
        } else if (field === "research_cooperators") {
          sraFormData[field] = SRAForm.research_cooperators_full_name
            ? [
                {
                  full_name: SRAForm.research_cooperators_full_name,
                  position: SRAForm.research_cooperators_position,
                  role: SRAForm.research_cooperators_role,
                },
              ]
            : null;
        } else if (field === "project_equipment_received") {
          sraFormData[field] = SRAForm.project_equipment_received_name
            ? [
                {
                  name: SRAForm.project_equipment_received_name,
                  type_or_model:
                    SRAForm.project_equipment_received_type_or_model,
                  quantity: SRAForm.project_equipment_received_quantity,
                },
              ]
            : null;
        } else {
          sraFormData[field] = SRAForm[field];
        }
      }

      sraFormData["user_id_seller"] = currentMeritoUser.user_id;
      sraFormData["user_id_buyer"] = null;

      console.log(`Calling Merito API: ${ENDPOINT_SRA_GENERATION}`);
      const authHeader = await firebaseBuildAuthHeader();
      const response = await axios.post(
        `${MERITO_API_HOST}${ENDPOINT_SRA_GENERATION}`,
        sraFormData,
        authHeader
      );
      const sra = response.data.content;

      console.log("SRA document generated:\n", sra);
      toast({
        status: "success",
        isClosable: true,
        title: "Success",
        description: SRAGenerated[i18n.language],
      });

      navigate("/science-deals/listing");
    } catch (err) {
      const errMsg = "Failed to generate SRA document:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: SRAGenerationFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Update project_id when location.state changes
    if (location.state && location.state.project_id) {
      setSRAForm({
        ...SRAForm,
        project_id: location.state.project_id,
      });
    }
  }, [location.state]);

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex
      w="full"
      justifyContent="center"
      alignItems="center"
      px={{ base: 4, md: 10 }}
      py={12}
    >
      <Box
        className={i18n.language === "jp" ? "jp" : "en"}
        w="full"
        maxW="1100px"
        bg="Neutral.0"
        color="ScienceDeals.600"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        px={{ base: 8, md: 20 }}
        py={10}
      >
        <VStack as="form" align="stretch">
          {/* Title */}
          <Text
            color="ScienceDeals.500"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight={500}
          >
            {t("title")}
          </Text>

          {SRAForm.project_id ? (
            <>
              {/* Subtitle */}
              <Text fontSize={{ base: "sm", md: "md" }} mb={5} px={1}>
                {t("subtitle")}
              </Text>
              <Divider
                borderColor="ScienceDeals.300"
                borderWidth="1.5px"
                mb={2}
              />

              {/* SRA Generation Form */}
              <VStack
                maxH="47vh"
                minH="400px"
                overflowX="scroll"
                overflowY="scroll"
                textAlign="left"
                spacing={7}
                px={{ base: 2, md: 4 }}
              >
                <FormControl
                  id="researcher_name"
                  isRequired
                  isInvalid={formErrorMessages.researcher_name}
                >
                  <FormLabel>
                    {t("researcher_name")}
                    <Tooltip
                      label={t("researcher_name_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="text"
                    name="researcher_name"
                    value={SRAForm.researcher_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                  <FormErrorMessage>
                    {formErrorMessages.researcher_name}
                  </FormErrorMessage>
                </FormControl>

                <FormControl id="sponsor_name">
                  <FormLabel>
                    {t("sponsor_name")}
                    <Tooltip
                      label={t("sponsor_name_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="text"
                    name="sponsor_name"
                    value={SRAForm.sponsor_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                </FormControl>

                <FormControl
                  id="research_purpose"
                  isRequired
                  isInvalid={formErrorMessages.research_purpose}
                >
                  <FormLabel>
                    {t("research_purpose")}
                    <Tooltip
                      label={t("research_purpose_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Textarea
                    name="research_purpose"
                    value={SRAForm.research_purpose}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_purpose}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="research_scope"
                  isRequired
                  isInvalid={formErrorMessages.research_scope}
                >
                  <FormLabel>
                    {t("research_scope")}
                    <Tooltip
                      label={t("research_scope_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Textarea
                    name="research_scope"
                    value={SRAForm.research_scope}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_scope}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="principal_investigator"
                  isRequired
                  isInvalid={
                    formErrorMessages.principal_investigator_full_name ||
                    formErrorMessages.principal_investigator_position ||
                    formErrorMessages.principal_investigator_role
                  }
                >
                  <FormLabel>{t("principal_investigator")}</FormLabel>
                  <FormHelperText>
                    {t("principal_investigator_full_name")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="principal_investigator_full_name"
                    value={SRAForm.principal_investigator_full_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.principal_investigator_full_name}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("principal_investigator_position")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="principal_investigator_position"
                    value={SRAForm.principal_investigator_position}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.principal_investigator_position}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("principal_investigator_role")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="principal_investigator_role"
                    value={SRAForm.principal_investigator_role}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.principal_investigator_role}
                  </FormErrorMessage>
                </FormControl>

                {/* TODO: Update to take multiple research_cooperator */}
                <FormControl
                  id="research_cooperators"
                  isInvalid={
                    formErrorMessages.research_cooperators_full_name ||
                    formErrorMessages.research_cooperators_position ||
                    formErrorMessages.research_cooperators_role
                  }
                >
                  <FormLabel>{t("research_cooperators")}</FormLabel>
                  <FormHelperText>
                    {t("research_cooperators_full_name")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_cooperators_full_name"
                    value={SRAForm.research_cooperators_full_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_cooperators_full_name}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_cooperators_position")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_cooperators_position"
                    value={SRAForm.research_cooperators_position}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_cooperators_position}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_cooperators_role")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_cooperators_role"
                    value={SRAForm.research_cooperators_role}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_cooperators_role}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="research_department"
                  isRequired
                  isInvalid={formErrorMessages.research_department}
                >
                  <FormLabel>{t("research_department")}</FormLabel>
                  <Input
                    type="text"
                    name="research_department"
                    value={SRAForm.research_department}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_department}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="project_term"
                  isRequired
                  isInvalid={formErrorMessages.project_term}
                >
                  <FormLabel>{t("project_term")}</FormLabel>
                  <Input
                    type="text"
                    name="project_term"
                    value={SRAForm.project_term}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_term}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="first_right_to_license"
                  isRequired
                  isInvalid={formErrorMessages.first_right_to_license}
                >
                  <FormLabel>{t("first_right_to_license")}</FormLabel>
                  <Select
                    name="first_right_to_license"
                    value={SRAForm.first_right_to_license}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    defaultValue="Select option"
                  >
                    <option value="Select option" disabled>
                      {t("select_option")}
                    </option>
                    <option value="Yes">{t("option_yes")}</option>
                    <option value="No">{t("option_no")}</option>
                  </Select>
                  <FormErrorMessage>
                    {formErrorMessages.first_right_to_license}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="license_type"
                  isRequired
                  isInvalid={formErrorMessages.license_type}
                >
                  <FormLabel>
                    {t("license_type")}
                    <Tooltip
                      label={t("license_type_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    name="license_type"
                    value={SRAForm.license_type}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    defaultValue="Select option"
                  >
                    <option value="Select option" disabled>
                      {t("select_option")}
                    </option>
                    <option value="Exclusive">{t("option_exclusive")}</option>
                    <option value="Non-exclusive">
                      {t("option_non_exclusive")}
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {formErrorMessages.license_type}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="research_sites"
                  isRequired
                  isInvalid={formErrorMessages.research_sites}
                >
                  <FormLabel>{t("research_sites")}</FormLabel>
                  <Input
                    type="text"
                    name="research_sites"
                    value={SRAForm.research_sites}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_sites}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="research_expenses"
                  isRequired
                  isInvalid={
                    formErrorMessages.research_expenses_currency ||
                    formErrorMessages.research_expenses_direct_expenses ||
                    formErrorMessages.research_expenses_travel_expenses ||
                    formErrorMessages.research_expenses_labor_fees ||
                    formErrorMessages.research_expenses_indirect_expenses ||
                    formErrorMessages.research_expenses_total_cost
                  }
                >
                  <FormLabel>{t("research_expenses")}</FormLabel>
                  <FormHelperText>
                    {t("research_expenses_currency")}
                  </FormHelperText>
                  <Select
                    name="research_expenses_currency"
                    value={SRAForm.research_expenses_currency}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    defaultValue="Select option"
                    mt={2}
                  >
                    <option value="Select option" disabled>
                      {t("select_option")}
                    </option>
                    <option value="USD">{t("option_usd")}</option>
                    <option value="JPY">{t("option_jpy")}</option>
                  </Select>
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_currency}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_expenses_direct_expenses")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_expenses_direct_expenses"
                    value={formattedNumbers.research_expenses_direct_expenses}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_direct_expenses}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_expenses_travel_expenses")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_expenses_travel_expenses"
                    value={formattedNumbers.research_expenses_travel_expenses}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_travel_expenses}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_expenses_labor_fees")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_expenses_labor_fees"
                    value={formattedNumbers.research_expenses_labor_fees}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_labor_fees}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_expenses_indirect_expenses")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_expenses_indirect_expenses"
                    value={formattedNumbers.research_expenses_indirect_expenses}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_indirect_expenses}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("research_expenses_total_cost")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="research_expenses_total_cost"
                    value={formattedNumbers.research_expenses_total_cost}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_expenses_total_cost}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="payment_terms"
                  isRequired
                  isInvalid={formErrorMessages.payment_terms}
                >
                  <FormLabel>{t("payment_terms")}</FormLabel>
                  <Select
                    name="payment_terms"
                    value={SRAForm.payment_terms}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    defaultValue="Select option"
                  >
                    <option value="Select option" disabled>
                      {t("select_option")}
                    </option>
                    <option value="Single payment">
                      {t("option_single_payment")}
                    </option>
                    <option value="Multiple payment based on milestones">
                      {t("option_multiple_payment")}
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {formErrorMessages.payment_terms}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="project_milestones"
                  isRequired
                  isInvalid={formErrorMessages.project_milestones}
                >
                  <FormLabel>{t("project_milestones")}</FormLabel>
                  <Textarea
                    name="project_milestones"
                    value={SRAForm.project_milestones}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_milestones}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="payment_due_dates"
                  isRequired
                  isInvalid={
                    formErrorMessages.payment_due_date_1st ||
                    formErrorMessages.payment_due_date_2nd ||
                    formErrorMessages.payment_due_date_3rd
                  }
                >
                  <FormLabel>{t("payment_due_dates")}</FormLabel>
                  <FormHelperText>{t("payment_due_date_1st")}</FormHelperText>
                  <Input
                    type="date"
                    name="payment_due_date_1st"
                    value={SRAForm.payment_due_date_1st}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.payment_due_date_1st}
                  </FormErrorMessage>

                  <FormHelperText>{t("payment_due_date_2nd")}</FormHelperText>
                  <Input
                    type="date"
                    name="payment_due_date_2nd"
                    value={SRAForm.payment_due_date_2nd}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.payment_due_date_2nd}
                  </FormErrorMessage>

                  <FormHelperText>{t("payment_due_date_3rd")}</FormHelperText>
                  <Input
                    type="date"
                    name="payment_due_date_3rd"
                    value={SRAForm.payment_due_date_3rd}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.payment_due_date_3rd}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="project_equipment_used"
                  isRequired
                  isInvalid={formErrorMessages.project_equipment_used}
                >
                  <FormLabel>
                    {t("project_equipment_used")}
                    <Tooltip
                      label={t("project_equipment_used_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Textarea
                    name="project_equipment_used"
                    value={SRAForm.project_equipment_used}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_equipment_used}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="project_received_equipment_from_sponsor"
                  isRequired
                  isInvalid={
                    formErrorMessages.project_received_equipment_from_sponsor
                  }
                >
                  <FormLabel>
                    {t("project_received_equipment_from_sponsor")}
                  </FormLabel>
                  <Select
                    name="project_received_equipment_from_sponsor"
                    value={SRAForm.project_received_equipment_from_sponsor}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    defaultValue="Select option"
                  >
                    <option value="Select option" disabled>
                      {t("select_option")}
                    </option>
                    <option value="Yes">{t("option_yes")}</option>
                    <option value="No">{t("option_no")}</option>
                  </Select>
                  <FormErrorMessage>
                    {formErrorMessages.project_received_equipment_from_sponsor}
                  </FormErrorMessage>
                </FormControl>

                {/* TODO: Update to take multiple project_equipment_received */}
                <FormControl
                  id="project_equipment_received"
                  isInvalid={
                    formErrorMessages.project_equipment_received_name ||
                    formErrorMessages.project_equipment_received_type_or_model ||
                    formErrorMessages.project_equipment_received_quantity
                  }
                >
                  <FormLabel>{t("project_equipment_received")}</FormLabel>
                  <FormHelperText>
                    {t("project_equipment_received_name")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="project_equipment_received_name"
                    value={SRAForm.project_equipment_received_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_equipment_received_name}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("project_equipment_received_type_or_model")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="project_equipment_received_type_or_model"
                    value={SRAForm.project_equipment_received_type_or_model}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_equipment_received_type_or_model}
                  </FormErrorMessage>

                  <FormHelperText>
                    {t("project_equipment_received_quantity")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="project_equipment_received_quantity"
                    value={formattedNumbers.project_equipment_received_quantity}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.project_equipment_received_quantity}
                  </FormErrorMessage>
                </FormControl>

                <FormControl id="other_sponsors">
                  <FormLabel>
                    {t("other_sponsors")}
                    <Tooltip
                      label={t("other_sponsors_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="text"
                    name="other_sponsors"
                    value={SRAForm.other_sponsors}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                </FormControl>

                <FormControl id="funding_sources_for_equipment">
                  <FormLabel>
                    {t("funding_sources_for_equipment")}
                    <Tooltip
                      label={t("funding_sources_for_equipment_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="text"
                    name="funding_sources_for_equipment"
                    value={SRAForm.funding_sources_for_equipment}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                </FormControl>

                <FormControl
                  id="research_report_due_in_months"
                  isRequired
                  isInvalid={formErrorMessages.research_report_due_in_months}
                >
                  <FormLabel>
                    {t("research_report_due_in_months")}
                    <Tooltip
                      label={t("research_report_due_in_months_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="text"
                    name="research_report_due_in_months"
                    value={formattedNumbers.research_report_due_in_months}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                  />
                  <FormErrorMessage>
                    {formErrorMessages.research_report_due_in_months}
                  </FormErrorMessage>
                </FormControl>

                <FormControl id="additional_provisions_from_sponsor">
                  <FormLabel>
                    {t("additional_provisions_from_sponsor")}
                  </FormLabel>
                  <Textarea
                    name="additional_provisions_from_sponsor"
                    value={SRAForm.additional_provisions_from_sponsor}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                </FormControl>

                <FormControl id="additional_provisions_from_lab">
                  <FormLabel>{t("additional_provisions_from_lab")}</FormLabel>
                  <Textarea
                    name="additional_provisions_from_lab"
                    value={SRAForm.additional_provisions_from_lab}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    size="md"
                  />
                </FormControl>

                <FormControl
                  id="administrative_contact"
                  isRequired
                  isInvalid={
                    formErrorMessages.administrative_contact_name ||
                    formErrorMessages.administrative_contact_title ||
                    formErrorMessages.administrative_contact_mailing_address ||
                    formErrorMessages.administrative_contact_email ||
                    formErrorMessages.administrative_contact_phone_number
                  }
                >
                  <FormLabel>{t("administrative_contact")}</FormLabel>
                  <FormHelperText>
                    {t("administrative_contact_name")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="administrative_contact_name"
                    value={SRAForm.administrative_contact_name}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.administrative_contact_name}
                  </FormErrorMessage>
                  <FormHelperText>
                    {t("administrative_contact_title")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="administrative_contact_title"
                    value={SRAForm.administrative_contact_title}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.administrative_contact_title}
                  </FormErrorMessage>
                  <FormHelperText>
                    {t("administrative_contact_mailing_address")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="administrative_contact_mailing_address"
                    value={SRAForm.administrative_contact_mailing_address}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.administrative_contact_mailing_address}
                  </FormErrorMessage>
                  <FormHelperText>
                    {t("administrative_contact_email")}
                  </FormHelperText>
                  <Input
                    type="text"
                    name="administrative_contact_email"
                    value={SRAForm.administrative_contact_email}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    // placeholder=""
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.administrative_contact_email}
                  </FormErrorMessage>
                  <FormHelperText>
                    {t("administrative_contact_phone_number")}
                    <Tooltip
                      label={t("administrative_contact_phone_number_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormHelperText>
                  <Input
                    type="text"
                    name="administrative_contact_phone_number"
                    value={SRAForm.administrative_contact_phone_number}
                    onChange={handleInputChange}
                    onBlur={handleInputValidation}
                    placeholder={t(
                      "administrative_contact_phone_number_placeholder"
                    )}
                    mt={2}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.administrative_contact_phone_number}
                  </FormErrorMessage>
                </FormControl>
              </VStack>

              {/* Form submission  */}
              <Divider
                borderColor="ScienceDeals.300"
                borderWidth="1.5px"
                mt={2}
              />
              <Flex justifyContent="center" alignItems="center">
                <Button
                  w="40"
                  variant="ScienceDeals"
                  fontSize={{ base: "md", md: "lg" }}
                  mt={6}
                  onClick={async (event) => await handleSRAGeneration(event)}
                >
                  {t("generate_sra")}
                </Button>
              </Flex>
            </>
          ) : (
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              alignItems="center"
              bg="Neutral.50"
              borderRadius="lg"
              mt={6}
              px={4}
              py={10}
            >
              <Text
                whiteSpace="normal"
                overflowWrap="break-word"
                color="Neutral.500"
              >
                {t("no_project_data")}
              </Text>
            </Flex>
          )}
        </VStack>
      </Box>
    </Flex>
  );
}
