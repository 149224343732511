import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DocumentViewer from "./DocumentViewer";
import {
  MERITO_PLATFORM_AGREEMENT_EN,
  MERITO_PLATFORM_AGREEMENT_JP,
} from "../utilities/MeritoPlatformAgreement";

const TITLE = "terms_and_conditions";

export default function TermsAndConditions() {
  const { i18n } = useTranslation();

  return (
    <Text as="span">
      <DocumentViewer
        title={TITLE}
        content={
          i18n.language === "jp"
            ? MERITO_PLATFORM_AGREEMENT_JP
            : MERITO_PLATFORM_AGREEMENT_EN
        }
      />
    </Text>
  );
}
