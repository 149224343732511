import axios from "axios";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_CONTACT_HOME = "/contact/home";

export default function HomeContactForm() {
  const { t, i18n } = useTranslation("contact");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [inquiryID, setInquiryID] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);

    try {
      console.log(`Calling Merito API: ${ENDPOINT_CONTACT_HOME}`);
      const response = await axios.post(
        `${MERITO_API_HOST}${ENDPOINT_CONTACT_HOME}`,
        {
          name: name,
          email: email,
          subject: subject,
          message: message,
        }
      );

      console.log("Inquiry confirmation email sent:\n", response.data.content);
      setInquiryID(response.data.content.id);
      // TODO: Add Toast for success message
    } catch (err) {
      const errMsg = "Failed to send the contact form:\n";
      console.error(errMsg, err);
      // TODO: Add Toast for error message
    }
  };

  useEffect(() => {
    setDisabled(name && email && message ? false : true);
  }, [name, email, message]);

  return (
    <Flex>
      <Stack
        className={i18n.language === "jp" ? "jp" : "en"}
        spacing={8}
        mx={"auto"}
        maxW={"2xl"}
        pt={12}
        pb={16}
        px={6}
      >
        {/* Title */}
        <Stack>
          <Text
            color="Neutral.0"
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "3xl", md: "4xl", lg: "4xl" }}
            textAlign={"center"}
          >
            {t("title")}
          </Text>
          {inquiryID && (
            <Text
              fontSize={"lg"}
              textAlign={"left"}
              color="Secondary.500"
              px={6}
              pt={4}
            >
              {t("description_top_1")}
              <br />
              {t("description_top_2")}
              <Text as="span" fontWeight={600}>
                {inquiryID}
              </Text>
            </Text>
          )}
        </Stack>

        {/* Form */}
        <Box
          borderRadius={"lg"}
          bg="Neutral.0"
          color="Neutral.800"
          boxShadow={"xl"}
          p={9}
        >
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>{t("name")}</FormLabel>
              <Input
                onChange={(e) => setName(e.target.value)}
                id="name"
                value={name}
                type="text"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t("email")}</FormLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                value={email}
                type="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("subject")}</FormLabel>
              <Input
                onChange={(e) => setSubject(e.target.value)}
                id="subject"
                value={subject}
                type="text"
                placeholder=""
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("message")}</FormLabel>
              <Textarea
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                value={message}
                type="text"
                placeholder=""
              />
            </FormControl>
            <Stack pt={4} px={1}>
              <Text align={"left"} color="Neutral.800" fontSize="14">
                {t("description_bottom_1")}
                <Text as="span" color="Secondary.700">
                  {t("description_bottom_2")}
                </Text>
                {t("description_bottom_3")}
              </Text>
            </Stack>
            <Stack py={3}>
              <Button
                className={i18n.language === "jp" ? "jp" : "en"}
                isDisabled={disabled}
                variant="OrangePrimary"
                onClick={handleSubmit}
              >
                {t("send_button")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
