import { Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

export const getCurrentJSTDateTime = () => {
  const jstMoment = moment.tz("Asia/Tokyo");
  // ISO 8601 format with timezone
  const jstString = jstMoment.format();
  return jstString;
};

export const parseNumber = (input) => {
  if (input === "" || input === null || input == undefined) return null;

  // Clean up the input
  // Ensure the input is a string for string manipulation
  input = input.toString();
  // Remove commas and parse as a float
  const number = parseFloat(input.replace(/,/g, ""));

  // If not a valid number, return null
  if (isNaN(number)) return null;

  return number;
};

export const parseNumberIntoInteger = (input) => {
  // Get cleaned up number
  const number = parseNumber(input);

  // If not a valid number or not a non-negative number, return null
  if (number === null || number < 0) return null;

  // Return rounded integer value
  return Math.round(number);
};

export const formatNumber = (input) => {
  // Get cleaned up number
  const number = parseNumber(input);

  // If not a valid number, return null
  if (number === null) return null;

  // Format the number with commas
  return number.toLocaleString("en-US", { maximumFractionDigits: 2 });
};

export const trimSpaces = (input) => {
  if (input === "" || input === null || input == undefined) return null;

  // Regular expression to match leading and trailing spaces (including full-width spaces)
  const regex = /^[\s\u3000]+|[\s\u3000]+$/g;

  // Replace leading and trailing spaces with an empty string
  return input.replace(regex, "");
};

export const convertFullWidthToHalfWidth = (input) => {
  // Convert full-width characters to half-width
  return input.replace(/[\uFF01-\uFF5E]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) - 0xfee0)
  );
};

// For password, username validation
export const isAlphaNumericOrSymbol = (input) => {
  if (input === "" || input === null || input == undefined) return false;

  // Regular expression to detect full-width characters
  const fullWidthRegex = /[\uFF01-\uFF5E\u3000-\u303F]/;

  // Check if the input contains any full-width characters
  if (fullWidthRegex.test(input)) {
    return false;
  }

  // Regular expression to match allowed half-width ASCII characters (excluding spaces)
  const halfWidthRegex = /^[\x21-\x7E]+$/;

  // Check if the input matches the regular expression
  return halfWidthRegex.test(input);
};

export const isValidEmail = (input) => {
  if (input === "" || input === null || input == undefined) return false;

  // Regular expression to validate email format
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the input matches the regular expression
  return regex.test(input);
};

export const isValidPhoneNumber = (input) => {
  if (input === "" || input === null || input == undefined) return false;

  // Regular expression to validate phone number format
  // ^\+      - The number must start with a "+"
  // [0-9]+   - Followed by one or more numbers/digits (country code + phone number)
  const regex = /^\+[0-9]+$/;

  // Check if the input matches the regular expression
  return regex.test(input);
};

export const downloadBase64File = (
  base64Data,
  fileName,
  mimeType = "application/pdf"
) => {
  // Convert Base64 string to binary data
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the binary data
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a link to download the Blob
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the object URL after the download starts
  URL.revokeObjectURL(link.href);
};

export const parseHTMLContent = (htmlContent) => {
  // Sanitize the HTML content to prevent XSS attacks
  const sanitizedContent = DOMPurify.sanitize(htmlContent);

  // Parse the sanitized HTML content
  return parse(sanitizedContent, {
    replace: (domNode) => {
      if (domNode.name === "h1" && domNode.attribs.class === "document-title") {
        return (
          <Text
            as="h1"
            textAlign="center"
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight={600}
            my="20px"
          >
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (
        domNode.name === "h2" &&
        domNode.attribs.class === "subtitle"
      ) {
        return (
          <Text as="h2" fontWeight={600} mb="20px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (
        domNode.name === "h3" &&
        domNode.attribs.class === "article-title"
      ) {
        return (
          <Text as="h3" textDecoration="underline" mt="15px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (domNode.name === "p" && domNode.attribs.class === "closing") {
        return (
          <Text as="p" textAlign="right" mt="40px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (domNode.name === "span" && domNode.attribs.class === "bold") {
        return (
          <Text as="span" fontWeight={600}>
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (
        domNode.name === "div" &&
        domNode.attribs.class === "indentation-1"
      ) {
        return (
          <Text as="div" ml="20px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (
        domNode.name === "div" &&
        domNode.attribs.class === "indentation-2"
      ) {
        return (
          <Text as="div" ml="40px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      } else if (domNode.name === "p") {
        return (
          <Text as="p" mt="5px">
            {domNode.children.map((child) =>
              parse(DOMPurify.sanitize(child.data))
            )}
          </Text>
        );
      }
      return domNode;
    },
  });
};
