import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  VStack,
  Container,
  Text,
  Button,
  Stack,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { LuGalleryVerticalEnd } from "react-icons/lu";

export default function ScienceDealsDashboard() {
  const navigate = useNavigate();

  return (
    <Box boxShadow="none">
      <Container
        h="82vh"
        maxW={{ base: "95vw", md: "80vw" }}
        mb={{ base: 14, md: 0 }}
      >
        <Flex
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* Title */}
          <Stack w="full" textAlign="center" my={{ base: 14, md: 20 }}>
            <Text
              whiteSpace="normal"
              overflowWrap="break-word"
              textAlign="center"
              color="Neutral.100"
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              mx={{ base: 10, md: 0 }}
            >
              Science Deals Dashboard
            </Text>
          </Stack>

          {/* Menu */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
            mx={{ base: 8, md: 8 }}
          >
            {/* Create Projects Button */}
            <Flex justifyContent="start">
              <Button
                w="full"
                maxW={{ base: "90vw", md: "40vw" }}
                bg="ScienceDeals.600"
                color="Neutral.50"
                fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
                role="group"
                _hover={{
                  bg: "ScienceDeals.800",
                  color: "Neutral.200",
                }}
                cursor={"pointer"}
                borderRadius={"2xl"}
                boxShadow={"2xl"}
                mr={{ base: 0, md: 7 }}
                mb={{ base: 4, md: 0 }}
                px={{ base: 16, md: 20 }}
                py={{ base: 28, md: 40 }}
                onClick={() => navigate("/science-deals/project-creation")}
              >
                <VStack>
                  <Text textAlign="center">
                    <Icon
                      as={MdOutlineLibraryAdd}
                      w={{ base: "8", md: "10" }}
                      h={{ base: "8", md: "10" }}
                      mr={4}
                      mb={-2}
                    />
                    Create Projects
                  </Text>
                  <Divider
                    orientation="horizontal"
                    borderColor="Neutral.950"
                    borderWidth="1.5px"
                    _groupHover={{
                      borderColor: "ScienceDeals.300",
                    }}
                    mt={7}
                    mb={6}
                  />
                  <Text
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    textAlign="left"
                    color="Neutral.950"
                    fontSize={{ base: "md", sm: "lg", md: "xl" }}
                    _groupHover={{
                      color: "ScienceDeals.300",
                    }}
                  >
                    List your research on the Merito platform.
                  </Text>
                </VStack>
              </Button>
            </Flex>

            {/* View Projects Button */}
            <Flex justifyContent="start">
              <Button
                w="full"
                maxW={{ base: "90vw", md: "40vw" }}
                bg="Neutral.100"
                color="ScienceDeals.500"
                fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
                role="group"
                _hover={{
                  bg: "Neutral.300",
                  color: "Neutral.0",
                }}
                cursor={"pointer"}
                borderRadius={"2xl"}
                boxShadow={"2xl"}
                ml={{ base: 0, md: 7 }}
                mt={{ base: 4, md: 0 }}
                px={{ base: 16, md: 20 }}
                py={{ base: 28, md: 40 }}
                onClick={() => navigate("/science-deals/listing")}
              >
                <VStack>
                  <Text>
                    <Icon
                      as={LuGalleryVerticalEnd}
                      w={{ base: "8", md: "10" }}
                      h={{ base: "8", md: "10" }}
                      mr={4}
                      mb={-2}
                    />
                    View Projects
                  </Text>
                  <Divider
                    orientation="horizontal"
                    borderColor="Neutral.500"
                    borderWidth="1.5px"
                    _groupHover={{
                      borderColor: "Neutral.900",
                    }}
                    mt={7}
                    mb={6}
                  />
                  <Text
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    textAlign="left"
                    color="Neutral.500"
                    fontSize={{ base: "md", sm: "lg", md: "xl" }}
                    _groupHover={{
                      color: "Neutral.900",
                    }}
                  >
                    Browse research on the Merito platform.
                  </Text>
                </VStack>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
