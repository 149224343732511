export const MERITO_PLATFORM_AGREEMENT_EN = `
<!DOCTYPE html>
<html lang="ja">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {{
      font-family: Arial, sans-serif;
    }}
    .document-title {{
      // h1
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 20px;
    }}
    .subtitle {{
      // h2
      font-weight: bold;
      margin-bottom: 20px;
    }}
    .article-title {{
      // h3
      text-decoration: underline;
      margin-top: 15px;
    }}
    .closing {{
      // p
      text-align: right;
      margin-top: 40px;
    }}
    .bold {{
      // span
      font-weight: bold;
    }}
    .indentation-1 {{
      // div
      margin-left: 20px;
    }}
    .indentation-2 {{
      // div
      margin-left: 40px;
    }}
  </style>
</head>
<body>
  <h1 class="document-title">Merito Platform Agreement</h1>

  <h2 class="subtitle">By using Merito, users agree to these terms of use:</h2>
  
  <span class="bold">1 Parties</span><span>: This agreement is between Merito ("Platform") and the undersigned science lab ("User").</span><br>
  <span class="bold">2 Service</span><span>: Merito provides a platform for startups to connect with companies.</span><br>
  <span class="bold">3 Obligation</span><span>: If User meets a company through the Platform, User agrees to conduct all related business transactions through Merito's services.</span><br>
  <span class="bold">4 Fee</span><span>: User shall pay Merito a platform fee equal to 9% of the total transaction price for any business conducted with companies met through the Platform.</span><br>
  <span class="bold">5 Term</span><span>: This agreement is effective upon User's registration on the Platform and remains in force until terminated by either party with 30 days' written notice.</span><br>
  <span class="bold">6 Governing Law</span><span>: This agreement shall be governed by law of Tokyo, Japan.</span>
</body>
</html>
`;

export const MERITO_PLATFORM_AGREEMENT_JP = `
<!DOCTYPE html>
<html lang="ja">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {{
      font-family: Arial, sans-serif;
    }}
    .document-title {{
      // h1
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 20px;
    }}
    .subtitle {{
      // h2
      font-weight: bold;
      margin-bottom: 20px;
    }}
    .article-title {{
      // h3
      text-decoration: underline;
      margin-top: 15px;
    }}
    .closing {{
      // p
      text-align: right;
      margin-top: 40px;
    }}
    .bold {{
      // span
      font-weight: bold;
    }}
    .indentation-1 {{
      // div
      margin-left: 20px;
    }}
    .indentation-2 {{
      // div
      margin-left: 40px;
    }}
  </style>
</head>
<body>
  <h1 class="document-title">Merito Platform Agreement</h1>

  <h2 class="subtitle">Meritoを利用することにより、ユーザーは以下の利用規約に同意したものとみなします：</h2>

  <span class="bold">1 当事者</span><span>: 本契約は、Merito（以下「プラットフォーム」）と署名するスタートアップ（以下「ユーザー」）との間で締結されます。</span><br>
  <span class="bold">2 サービス</span><span>: Meritoは、スタートアップが企業と繋がるためのプラットフォームを提供します。</span><br>
  <span class="bold">3 義務</span><span>: ユーザーがプラットフォームを通じて企業と出会った場合、ユーザーはMeritoのサービスを通じてすべての関連するビジネス取引を行うことに同意します。</span><br>
  <span class="bold">4 手数料</span><span>: ユーザーは、プラットフォームを通じて出会った企業との取引について、取引総額の9%をプラットフォーム手数料としてMeritoに支払うものとします。</span><br>
  <span class="bold">5 期間</span><span>: 本契約は、ユーザーがプラットフォームに登録した時点で発効し、いずれかの当事者が30日前に書面で通知するまで有効です。</span><br>
  <span class="bold">6 準拠法</span><span>: 本契約は東京、日本に準拠するものとします。</span>
</body>
</html>
`;
