import React, { useState } from "react";
import { Box, Flex, Button, Text, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import "../App.css";
import MeritoLogo from "../assets/logo_dark.svg";
import { parseHTMLContent } from "../utilities/utilities";

export default function DocumentViewer({ title, content }) {
  const { t, i18n } = useTranslation("document_viewer");

  return (
    <Flex
      className={i18n.language === "jp" ? "jp" : "en"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      maxW="1200px"
      mx={{ base: 8, md: 16, lg: 24, xl: 32 }}
      my={{ base: 12, md: 14, lg: 16 }}
    >
      {/* Top Title */}
      <Box
        w="full"
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        bg="Neutral.700"
        color="Neutral.0"
        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
        borderTopRadius="xl"
        gap={{ base: 2, md: 4 }}
        px={2}
        py={{ base: 6, sm: 10 }}
      >
        <Image
          src={MeritoLogo}
          alt="Logo"
          boxSize={{ base: "26px", md: "32px", lg: "36px" }}
        />
        <Text>{t(title)}</Text>
      </Box>

      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        bg="Neutral.0"
        color="ScienceDeals.600"
        borderBottomRadius="xl"
        gap={8}
        p={10}
      >
        <Box
          maxH="50vh"
          overflowY="scroll"
          color="Neutral.800"
          fontSize={{ base: "sm", md: "md" }}
          borderWidth="1px"
          borderColor="Neutral.100"
          borderRadius="md"
          px={6}
          py={4}
        >
          {parseHTMLContent(content)}
        </Box>
        <Button
          variant="OrangeSecondary"
          fontSize={{ base: "md", md: "lg" }}
          onClick={() => {
            window.close();
          }}
        >
          {t("close")}
        </Button>
      </Flex>
    </Flex>
  );
}
