import { initializeApp } from "firebase/app";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;

export const firebaseConfig = {
  apiKey:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_APIKEY_${MERITO_ENVIRONMENT}`
    ],
  authDomain:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_AUTHDOMAIN_${MERITO_ENVIRONMENT}`
    ],
  projectId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_PROJECTID_${MERITO_ENVIRONMENT}`
    ],
  storageBucket:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_STORAGEBUCKET_${MERITO_ENVIRONMENT}`
    ],
  messagingSenderId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_MESSAGINGSENDERID_${MERITO_ENVIRONMENT}`
    ],
  appId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_APPID_${MERITO_ENVIRONMENT}`
    ],
  measurementId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_MEASUREMENTID_${MERITO_ENVIRONMENT}`
    ],
};
