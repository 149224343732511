import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  VStack,
  Image,
  Icon,
  createIcon,
  Avatar,
  Center,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/UserAuthProvider";
import { downloadBase64File } from "../utilities/utilities";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_USER_RESOURCES = "/user/user_id/resources";

export default function Portal() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("portal");
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const [resources, setResources] = useState(null);
  const [resourceCategory, setResourceCategory] = useState("project");
  const [isLoadingResources, setIsLoadingResources] = useState(true);

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkSignInStatus();

    if (currentMeritoUser) {
      const getResources = async () => {
        try {
          console.log(`Calling Merito API: ${ENDPOINT_USER_RESOURCES}`);
          const authHeader = await firebaseBuildAuthHeader();
          const response = await axios.get(
            `${MERITO_API_HOST}${ENDPOINT_USER_RESOURCES.replace(
              "user_id",
              currentMeritoUser.user_id
            )}`,
            authHeader
          );

          const content = response.data.content;
          if (
            content.project.seller.length > 0 ||
            content.project.buyer.length > 0 ||
            content.sra.seller.length > 0 ||
            content.sra.buyer.length > 0
          ) {
            setResources(response.data.content);
          } else {
            setResources(null);
          }

          setIsLoadingResources(false);
          console.log("Resources retrieved");
          // TODO: Add Toast for success message
        } catch (err) {
          const errMsg = "Failed to retrieve Resources:\n";
          console.error(errMsg, err);
          // TODO: Add Toast for error message
        }
      };

      getResources();
    }
  }, [meritoSignedIn]);

  return (
    <Container maxW={{ base: "100%", xl: "70%" }} py={12}>
      {/* Title */}
      <Text
        className={i18n.language === "jp" ? "jp" : "en"}
        textAlign="center"
        color="Neutral.100"
        fontSize={{ base: "2xl", md: "3xl" }}
        mt={{ base: 4, md: 6 }}
        mb={{ base: 12, md: 16 }}
      >
        {t("title")}
        <Text as="span" color="Secondary.400">
          {currentMeritoUser?.username}
        </Text>
      </Text>

      {/* Items */}
      <Flex
        direction={{ base: "column", xl: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <Container w={{ base: "full", xl: "50%" }}>
          {/* Profile Page */}
          <Stack
            w="full"
            flex={{ base: 1, md: 0 }}
            justify="center"
            align="center"
            direction="column"
            bg="Neutral.300"
            color="Neutral.0"
            borderRadius="xl"
            boxShadow="2xl"
            _hover={{
              bg: "Neutral.400",
              boxShadow: "xl",
            }}
            cursor="pointer"
            py={{ base: 8, md: 16 }}
            onClick={() => navigate(`/user/${currentMeritoUser.user_id}`)}
          >
            <Avatar
              name={currentMeritoUser?.username}
              src={currentMeritoUser?.user_image_url || ""}
              bg="ScienceDeals.700"
              color="Neutral.50"
              size="xl"
              mt={2}
              mb={4}
            />
            <Text fontSize={{ base: "xl", md: "2xl" }}>Profile Settings</Text>
          </Stack>

          {/* Science Deals and Patent Deals */}
          <Stack
            w="full"
            flex={{ base: 1, md: 0 }}
            direction={{ base: "column", md: "row" }}
            align="center"
            spacing={7}
            mt={7}
          >
            {/* Science Deals */}
            <Button
              w={{ base: "full", md: "full" }}
              bg="ScienceDeals.500"
              color="Neutral.0"
              fontSize={{ base: "xl", md: "2xl" }}
              borderRadius="xl"
              boxShadow="2xl"
              _hover={{
                bg: "ScienceDeals.600",
                boxShadow: "xl",
              }}
              cursor="pointer"
              py={{ base: 12, md: 24 }}
              onClick={() => navigate("/science-deals/dashboard")}
            >
              <Text as="span" whiteSpace="normal" overflowWrap="break-word">
                Science Deals
              </Text>
            </Button>

            {/* Patent Deals - TODO: Enable Patent Deals when ready */}
            {/* <Button
              w={{ base: "full", md: "50%" }}
              bg="PatentDeals.500"
              color="Neutral.0"
              fontSize={{ base: "xl", md: "2xl" }}
              borderRadius="xl"
              boxShadow="2xl"
              _hover={{
                bg: "PatentDeals.600",
                boxShadow: "xl",
              }}
              cursor="pointer"
              py={{ base: 12, md: 24 }}
              onClick={() => navigate("/patent-deals/dashboard")}
            >
              <Text as="span" whiteSpace="normal" overflowWrap="break-word">
                Patent Deals
              </Text>
            </Button> */}
          </Stack>
        </Container>

        {/* Resources */}
        <Container w={{ base: "full", xl: "50%" }} mt={{ base: 7, xl: 0 }}>
          <Box
            bg="Neutral.100"
            border="1px"
            borderColor="Neutral.200"
            borderRadius="xl"
            boxShadow="2xl"
            _hover={{
              boxShadow: "xl",
            }}
            p={8}
            pt={9}
          >
            {/* Title */}
            <Text
              color="Neutral.700"
              textAlign="center"
              fontSize={{ base: "xl", md: "2xl" }}
              mb={3}
            >
              Resources
            </Text>

            {/* Resource Category Selector */}
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              mb={4}
            >
              <Button
                w="full"
                bg={
                  resourceCategory === "project" ? "Neutral.50" : "Neutral.200"
                }
                color="Neutral.700"
                fontSize={{ base: "sm", md: "md" }}
                border="1px"
                borderColor="Neutral.200"
                borderRadius="md"
                boxShadow={resourceCategory === "project" ? "md" : "none"}
                _hover={{
                  bg: "Neutral.50",
                  boxShadow: "md",
                }}
                onClick={() => setResourceCategory("project")}
              >
                Projects
              </Button>
              <Button
                w="full"
                bg={resourceCategory === "sra" ? "Neutral.50" : "Neutral.200"}
                color="Neutral.700"
                fontSize={{ base: "sm", md: "md" }}
                border="1px"
                borderColor="Neutral.200"
                borderRadius="md"
                boxShadow={resourceCategory === "sra" ? "md" : "none"}
                _hover={{
                  bg: "Neutral.50",
                  boxShadow: "md",
                }}
                onClick={() => setResourceCategory("sra")}
              >
                SRAs
              </Button>
            </Flex>

            {/* Resource List */}
            <VStack
              h="330px"
              overflowY="scroll"
              spacing={4}
              align="start"
              border="1px"
              borderColor="Neutral.300"
              borderRadius="md"
              p={1}
            >
              {resources ? (
                resourceCategory === "project" ? (
                  resources.project.seller.length > 0 ? (
                    resources.project.seller.map((project, index) => (
                      <Box
                        key={index}
                        role="group"
                        w="full"
                        bg="Neutral.50"
                        color="Neutral.800"
                        border="1px"
                        borderColor="Neutral.300"
                        borderRadius="md"
                        boxShadow="xl"
                        _hover={{
                          bg: "Neutral.100",
                          boxShadow: "lg",
                          cursor: "pointer",
                        }}
                        p={4}
                        onClick={() =>
                          navigate(
                            `/science-deals/project/${project.project_id}`,
                            {
                              state: { project },
                            }
                          )
                        }
                      >
                        <Text
                          color="Neutral.600"
                          fontSize={{ base: "sm", md: "md" }}
                          _groupHover={{
                            color: "Primary.700",
                          }}
                        >
                          {project.project_name}
                        </Text>
                        <Text
                          noOfLines={2}
                          color="Neutral.400"
                          fontSize={{ base: "xs", md: "sm" }}
                          mt={1}
                        >
                          {project.project_goal}
                        </Text>
                      </Box>
                    ))
                  ) : (
                    <Flex
                      w="full"
                      h="full"
                      justify="center"
                      align="center"
                      p={4}
                    >
                      <Text
                        whiteSpace="normal"
                        overflowWrap="break-word"
                        fontSize={{ base: "md", md: "lg" }}
                        color="Neutral.500"
                      >
                        No Projects
                      </Text>
                    </Flex>
                  )
                ) : resources.sra.seller.length > 0 ? (
                  resources.sra.seller.map((sra, index) => (
                    <Box
                      key={index}
                      role="group"
                      w="full"
                      bg="Neutral.50"
                      color="Neutral.800"
                      border="1px"
                      borderColor="Neutral.300"
                      borderRadius="md"
                      boxShadow="xl"
                      _hover={{
                        bg: "Neutral.100",
                        boxShadow: "lg",
                        cursor: "pointer",
                      }}
                      p={4}
                      onClick={() => {
                        downloadBase64File(
                          sra.sra_document,
                          sra.sra_document_name
                        );
                      }}
                    >
                      <Text
                        color="Neutral.600"
                        fontSize={{ base: "sm", md: "md" }}
                        _groupHover={{
                          color: "Primary.700",
                        }}
                      >
                        ID: {sra.sra_id}
                      </Text>
                      <Text
                        color="Neutral.400"
                        fontSize={{ base: "xs", md: "sm" }}
                        mt={1}
                      >
                        Researcher Name: {sra.researcher_name}
                      </Text>
                      <Text
                        color="Neutral.400"
                        fontSize={{ base: "xs", md: "sm" }}
                        mt={1}
                      >
                        Sponsor Name: {sra.sponsor_name}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Flex w="full" h="full" justify="center" align="center" p={4}>
                    <Text
                      whiteSpace="normal"
                      overflowWrap="break-word"
                      fontSize={{ base: "md", md: "lg" }}
                      color="Neutral.500"
                    >
                      No SRAs
                    </Text>
                  </Flex>
                )
              ) : (
                <Flex w="full" h="full" justify="center" align="center" p={4}>
                  <Text
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    fontSize={{ base: "md", md: "lg" }}
                    color="Neutral.500"
                  >
                    {isLoadingResources
                      ? "Loading Resources..."
                      : "You haven't added any resources yet"}
                  </Text>
                </Flex>
              )}
            </VStack>
          </Box>
        </Container>
      </Flex>
    </Container>
  );
}
