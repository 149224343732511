import axios from "axios";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/UserAuthProvider";
import { emailVerified } from "../utilities/messages";

export default function EmailVerification({ signUpForm, setAccountCreated }) {
  const navigate = useNavigate();
  const toast = useToast();
  const { t, i18n } = useTranslation("sign_up");
  const { meritoUserSignUp } = useUserAuth();

  const auth = getAuth();

  const hideEmail = (email) => {
    // name@host.com -> n***@host.com
    if (email) {
      const [user, domain] = email.split("@");
      return `${user[0]}***@${domain}`;
    }
  };

  useEffect(() => {
    let intervalId = null;
    const checkEmailVerified = async () => {
      // Reload the user to check if email is verified
      await auth.currentUser.reload();
      const userEmailVerified = auth.currentUser.emailVerified;

      if (userEmailVerified) {
        // Clear the interval as soon as the email is verified
        clearInterval(intervalId);
        console.log(`Email verified: ${signUpForm.email}`);
        toast({
          status: "success",
          isClosable: true,
          title: "Success",
          description: emailVerified[i18n.language],
        });

        // Use FormData for File Uploads
        const signUpFormData = new FormData();
        signUpFormData.append("email", signUpForm.email);
        signUpFormData.append("phone_number", signUpForm.phone_number);
        signUpFormData.append("first_name", signUpForm.first_name);
        signUpFormData.append("last_name", signUpForm.last_name);
        signUpFormData.append("username", signUpForm.username);
        if (signUpForm.user_image) {
          signUpFormData.append("user_image", signUpForm.user_image);
        }

        // Create a new user in Merito DB and store the user in the context
        const _ = await meritoUserSignUp(auth.currentUser, signUpFormData);

        // Move to the sign-in page
        navigate("/sign-in");
        setAccountCreated(false);
      }
    };

    // Check the verification status every 2 seconds
    intervalId = setInterval(checkEmailVerified, 2000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [auth]);

  return (
    <Text className={i18n.language === "jp" ? "jp" : "en"}>
      {t("verification_message_1")}
      <br />
      {t("verification_message_2")}
      <Text as="span" color="ScienceDeals.500" fontWeight="600">
        {hideEmail(signUpForm.email)}
      </Text>
      {t("verification_message_3")}
      <br />
      <br />
      {t("verification_message_4")}
      <br />
      <br />
      {t("verification_message_5")}
    </Text>
  );
}
