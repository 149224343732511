import React, { createContext, useContext, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="modal"
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "transparent",
          }}
        >
          <Spinner
            bg="Neutral.0"
            color="Secondary.400"
            h={{ base: 16, md: 20 }}
            w={{ base: 16, md: 20 }}
            thickness={{ base: "6px", md: "8px" }}
            speed="1s"
          />
        </Box>
      )}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
