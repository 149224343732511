import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  getIdToken,
  onAuthStateChanged,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { match } from "path-to-regexp";

import { firebaseConfig } from "../firebase_authentication/firebaseConfig";
import { useLoading } from "./LoadingProvider";
import {
  accountCreated,
  userSignedOut,
  passwordResetEmailSent,
  accountCreationFailed,
  emailVerificationFailed,
  signInFailed,
  signOutFailed,
  passwordResetFailed,
  firebaseAccountCreated,
} from "../utilities/messages";

export const UserAuthContext = createContext();
export const firebaseApp = initializeApp(firebaseConfig);

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_USER_SIGN_UP = "/user/sign_up";
const ENDPOINT_USER_SIGN_IN = "/user/sign_in";

export const UserAuthProvider = ({ children }) => {
  // List of paths that require user auth check
  const PATHS_TO_SKIP_USER_AUTH_CHECK = [
    "/creators",
    "/buyers",
    "/about-us",
    "/contact",
    "/terms-and-conditions",
    "/privacy-policy",
  ];

  const location = useLocation();
  const toast = useToast();
  const { i18n } = useTranslation();
  const { setIsLoading } = useLoading();

  const [currentMeritoUser, setCurrentMeritoUser] = useState(null);
  const [meritoSignedIn, setMeritoSignedIn] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const skipsUserAuthCheck = (currentPath) => {
    return PATHS_TO_SKIP_USER_AUTH_CHECK.some((path) => {
      const matcher = match(path, { decode: decodeURIComponent });
      return matcher(currentPath);
    });
  };

  const firebaseUserSignUp = async (email, password) => {
    setIsLoading(true);
    setIsProcessing(true);
    const auth = getAuth();

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      // Send verification email to the user
      await sendEmailVerification(firebaseUser);
      console.log("Firebase user signed up and verification email sent");
      toast({
        status: "info",
        isClosable: true,
        title: "Info",
        description: firebaseAccountCreated[i18n.language],
      });

      return firebaseUser;
    } catch (err) {
      const errMsg =
        "Failed to sign up Firebase user or send verification email:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: accountCreationFailed[i18n.language],
      });
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: emailVerificationFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseUserSignIn = async (email, password) => {
    setIsLoading(true);
    setIsProcessing(true);
    const auth = getAuth();

    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      return firebaseUser;
    } catch (err) {
      const errMsg = "Failed to sign in to Firebase:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: signInFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseUserSignOut = async () => {
    setIsLoading(true);
    const auth = getAuth();

    try {
      await signOut(auth);
      console.log("User signed out");
      // toast({
      //   status: "success",
      //   isClosable: true,
      //   title: "Success",
      //   description: userSignedOut[i18n.language],
      // });

      // Update the user context and sign in status
      localStorage.setItem("meritoSignedIn", JSON.stringify(false));
      setCurrentMeritoUser(null);
      setMeritoSignedIn(false);
    } catch (err) {
      const errMsg = "Failed to sign out:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: signOutFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const firebasePasswordReset = async (email) => {
    setIsLoading(true);
    const auth = getAuth();

    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent");
      toast({
        status: "success",
        isClosable: true,
        title: "Success",
        description: passwordResetEmailSent[i18n.language],
      });
    } catch (err) {
      const errMsg = "Failed to send password reset email:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: passwordResetFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseBuildAuthHeader = async () => {
    const auth = getAuth();

    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser) {
        const idToken = await getIdToken(firebaseUser, true);
        console.log("Firebase ID Token retrieved");
        // TODO: Add Toast for success message

        // If user is signed in, return an authorization header with ID token
        const authHeader = {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        return authHeader;
      } else {
        // If user is not signed in, omit the authorization header entirely
        return {};
      }
    } catch (err) {
      const errMsg = "Failed to retrieve Firebase ID token:\n";
      console.error(errMsg, err);
      // TODO: Add Toast for error message
    }
  };

  const meritoUserSignUp = async (firebaseUser, signUpFormData) => {
    setIsLoading(true);

    try {
      if (firebaseUser) {
        // Send ID token and user data to create a new user in Merito DB
        console.log(`Calling Merito API: ${ENDPOINT_USER_SIGN_UP}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.post(
          `${MERITO_API_HOST}${ENDPOINT_USER_SIGN_UP}`,
          signUpFormData,
          {
            headers: {
              ...authHeader.headers,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const meritoUser = response.data.content;
        console.log("Merito user signed up");
        toast({
          status: "success",
          isClosable: true,
          title: "Success",
          description: accountCreated[i18n.language],
        });

        await firebaseUserSignOut();
        return meritoUser;
      } else {
        const errMsg = "Firebase user not found";
        console.error(errMsg);
        // TODO: Add Toast for error message
        return null;
      }
    } catch (err) {
      const errMsg = "Failed to sign up Merito user:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: accountCreationFailed[i18n.language],
      });
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
    }
  };

  const meritoUserSignIn = async (firebaseUser) => {
    setIsLoading(true);

    try {
      if (firebaseUser) {
        // Send ID token and verify it
        console.log(`Calling Merito API: ${ENDPOINT_USER_SIGN_IN}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.post(
          `${MERITO_API_HOST}${ENDPOINT_USER_SIGN_IN}`,
          null, // No request body
          authHeader
        );

        // Update the user context and sign in status
        const meritoUser = response.data.content;
        localStorage.setItem("meritoSignedIn", JSON.stringify(true));
        setCurrentMeritoUser(meritoUser);
        setMeritoSignedIn(true);

        console.log("User signed in:\n", meritoUser);
        // TODO: Add Toast for success message

        return meritoUser;
      } else {
        // Update the user context and sign in status
        localStorage.setItem("meritoSignedIn", JSON.stringify(false));
        setCurrentMeritoUser(null);
        setMeritoSignedIn(false);

        const errMsg = "Firebase user not found";
        console.error(errMsg);
        // TODO: Add Toast for error message
        return null;
      }
    } catch (err) {
      const errMsg = "Failed to sign in to Merito";
      console.error(errMsg, err);
      // TODO: Turn off/skip sign in function execution during sign up process and enale the toast
      // toast({
      //   status: "error",
      //   isClosable: true,
      //   title: "Error",
      //   description: signInFailed[i18n.language],
      // });
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
    }
  };

  // Update the user context and sign in status when location changes
  useEffect(() => {
    // Skip the proper user auth check on the paths that don't require it
    if (skipsUserAuthCheck(location.pathname)) {
      return;
    }

    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setIsLoading(true);

      if (firebaseUser) {
        // User is signed in in Firebase
        if (isProcessing) {
          // Skip the process if the user is currently signing up
          setIsLoading(false);
          return;
        }

        if (
          JSON.parse(localStorage.getItem("meritoSignedIn")) === false ||
          currentMeritoUser === null ||
          meritoSignedIn === false
        ) {
          // Sign in the user in Merito and update the user context and sign in status
          const _ = await meritoUserSignIn(firebaseUser);
        }
      } else {
        // User is signed out in Firebase
        // Update the user context and sign in status
        localStorage.setItem("meritoSignedIn", JSON.stringify(false));
        setCurrentMeritoUser(null);
        setMeritoSignedIn(false);
      }

      console.log(
        `Sign In Status: ${JSON.parse(localStorage.getItem("meritoSignedIn"))}`
      );
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [location]);

  return (
    <UserAuthContext.Provider
      value={{
        // States
        currentMeritoUser,
        setCurrentMeritoUser,
        meritoSignedIn,
        setMeritoSignedIn,
        isProcessing,
        setIsProcessing,
        // Functions
        firebaseUserSignUp,
        firebaseUserSignIn,
        firebaseUserSignOut,
        firebasePasswordReset,
        firebaseBuildAuthHeader,
        meritoUserSignUp,
        meritoUserSignIn,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
