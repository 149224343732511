// Messages used for form validation, action success, action error, action loading, and action warning
// Used in '<FormErrorMessage>' and 'useToast()'
// Check usage in 'src/components/ScienceDealsProjectCreation.jsx'

// Form field error messages
export const emptyFieldError = {
  en: "This field is required",
  jp: "このフィールドは必須です",
};

export const invalidEmailError = {
  en: "Invalid email",
  jp: "無効なメールアドレスです",
};

export const invalidPhoneNumberError = {
  en: "Invalid phone number",
  jp: "無効な電話番号です",
};

export const invalidUsernameError = {
  en: "Invalid username",
  jp: "無効なユーザー名です",
};

export const invalidPasswordError = {
  en: "Invalid password",
  jp: "無効なパスワードです",
};

export const emailExistsError = {
  en: "Email already exists",
  jp: "このメールアドレスは既に使用されています",
};

export const phoneNumberNameExistsError = {
  en: "Phone number already exists",
  jp: "この電話番号は既に使用されています",
};

export const usernameExistsError = {
  en: "Username already exists",
  jp: "このユーザー名は既に使用されています",
};

export const projectNameExistsError = {
  en: "Project name already exists",
  jp: "このプロジェクト名は既に使用されています",
};

export const passwordMismatchError = {
  en: "Passwords do not match",
  jp: "パスワードが一致しません",
};

// Form error messages
export const formIncompleteMessage = {
  en: "Please check warnings and complete all required fields",
  jp: "エラーメッセージを確認し、必須項目をすべて入力してください",
};

export const fillAll3FieldsMessage = {
  en: "Please fill all three fields",
  jp: "3つのフィールドすべてを入力してください",
};

// Action success messages
export const accountCreated = {
  en: "Account created",
  jp: "アカウントが登録されました",
};

export const emailVerified = {
  en: "Email verification completed",
  jp: "メールアドレスの認証が完了しました",
};

export const userSignedOut = {
  en: "Signed out",
  jp: "ログアウトしました",
};

export const passwordResetEmailSent = {
  en: "Password reset email has been sent. Please check your inbox.",
  jp: "パスワードリセットのメールが送信されました。受信箱を確認してください。",
};

export const projectCreated = {
  en: "Project created",
  jp: "プロジェクトが作成されました",
};

export const SRAGenerated = {
  en: "SRA generated",
  jp: "SRAが作成されました",
};

// Action error messages
export const accountCreationFailed = {
  en: "Account creation failed",
  jp: "アカウントの作成に失敗しました",
};

export const emailVerificationFailed = {
  en: "Email verification failed",
  jp: "メールアドレスの認証に失敗しました",
};

export const signInFailed = {
  en: "Sign in failed",
  jp: "ログインに失敗しました",
};

export const signOutFailed = {
  en: "Sign out failed",
  jp: "ログアウトに失敗しました",
};

export const passwordResetFailed = {
  en: "Failed to send password reset email",
  jp: "パスワードリセットのメール送信に失敗しました",
};

export const projectCreationFailed = {
  en: "Project creation failed",
  jp: "プロジェクトの作成に失敗しました",
};

export const SRAGenerationFailed = {
  en: "SRA generation failed",
  jp: "SRAの作成に失敗しました",
};

export const projectListingLoadFailed = {
  en: "Failed to load Science Deals projects",
  jp: "プロジェクトの読み込みに失敗しました",
};

export const projectDetailLoadFailed = {
  en: "Failed to load project detail",
  jp: "プロジェクトの詳細の読み込みに失敗しました",
};

export const projectNotFound = {
  en: "Project not found",
  jp: "プロジェクトが見つかりませんでした",
};

// Action loading messages
export const isLoadingProjectListing = {
  en: "Loading Science Deals projects...",
  jp: "プロジェクトを読み込んでいます...",
};

export const isLoadingProjectDetail = {
  en: "Loading project detail...",
  jp: "プロジェクトの詳細を読み込んでいます...",
};

// Action warning messages

// Action info messages
export const firebaseAccountCreated = {
  en: "Account creation is in progress. Please keep this page open and verify your email before signing in.",
  jp: "アカウントを作成中です。このページは閉じずに、続けてメールアドレスの認証を行ってください。",
};

export const noProjectsListed = {
  en: "No projects are currently listed",
  jp: "現在、プロジェクトはリストされていません",
};
