import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MdOutlineScience } from "react-icons/md";
import { PiMoney } from "react-icons/pi";
import { LuGalleryVerticalEnd } from "react-icons/lu";
import { IoPerson } from "react-icons/io5";
import { RiQuestionLine } from "react-icons/ri";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";

import { useUserAuth } from "../context/UserAuthProvider";
import LanguagePicker from "./LanguagePicker";
import "../App.css";
import { ReactComponent as MeritoLogo } from "../assets/logo_header.svg";

const DESKTOP_ITEMS = [
  {
    label: "creators",
    path: "/creators",
  },
  {
    label: "buyers",
    path: "/buyers",
  },
  {
    label: "projects",
    path: "/science-deals/listing",
  },
];

const MOBILE_ITEMS = [
  {
    label: "creators",
    subLabel: "Unlock the potential of your research",
    path: "/creators",
    icon: MdOutlineScience,
  },
  {
    label: "buyers",
    subLabel: "Discover how your funding changes lives",
    path: "/buyers",
    icon: PiMoney,
  },
  {
    label: "projects",
    subLabel: "Browse Science Deals projects",
    path: "/science-deals/listing",
    icon: LuGalleryVerticalEnd,
  },
  {
    label: "account",
    subLabel: "Manage your account",
    children: [
      {
        label: "portal",
        subLabel: "Explore Merito Platform",
        path: "/portal",
      },
      {
        label: "sign_in",
        subLabel: "Sign in to your account",
        path: "/sign-in",
      },
      {
        label: "sign_up",
        subLabel: "Create a new account",
        path: "/sign-up",
      },
      {
        label: "sign_out",
        subLabel: "Sign out of your account",
        path: "/",
      },
    ],
    icon: IoPerson,
  },
  {
    label: "contact",
    // subLabel: "Get in touch with us",
    path: "/contact",
    icon: RiQuestionLine,
  },
];

const DesktopParent = ({ items }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("header");

  return (
    <Stack
      className={i18n.language === "jp" ? "jp" : "en"}
      direction={"row"}
      spacing={4}
    >
      {items.map((item) => (
        <Box key={item.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                onClick={() => {
                  if (!item.children) {
                    if (item.label === "sign_out") firebaseUserSignOut();
                    console.log(item.path);
                    navigate(item.path);
                  }
                }}
                color="Secondary.300"
                fontSize={"m"}
                fontWeight={600}
                _hover={{
                  textDecoration: "none",
                  bg: "Neutral.700",
                }}
                borderRadius="md"
                px={4}
                py={3}
              >
                {t(item.label)}
                {item.children && (
                  <Icon
                    as={ChevronDownIcon}
                    w={6}
                    h={6}
                    color="Secondary.300"
                    ml={2}
                  />
                )}
              </Link>
            </PopoverTrigger>

            {item.children && (
              <PopoverContent
                bg="Neutral.100"
                borderRadius="md"
                boxShadow={"2xl"}
              >
                <Stack>
                  {item.children.map((child) => (
                    <DesktopChild key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopChild = ({ label, subLabel, path }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("header");
  const { meritoSignedIn, firebaseUserSignOut } = useUserAuth();

  return (
    <Link
      onClick={() => {
        if (label === "sign_out") firebaseUserSignOut();
        navigate(path);
      }}
      role={"group"}
      display={"block"}
      color="Secondary.400"
      _hover={{
        bg: "Neutral.200",
      }}
      p={5}
    >
      <Stack
        hidden={
          ((label === "sign_out" || label === "portal") && !meritoSignedIn) ||
          ((label === "sign_in" || label === "sign_up") && meritoSignedIn)
        }
        className={i18n.language === "jp" ? "jp" : "en"}
        direction={"row"}
        align={"center"}
      >
        <Box>
          <Text transition={"all .3s ease"} fontWeight={600}>
            {t(label)}
          </Text>
          {subLabel && (
            <Text
              color="Neutral.500"
              _hover={{ color: "Neutral.600" }}
              fontSize={"sm"}
              fontWeight={500}
            >
              {subLabel}
            </Text>
          )}
        </Box>
        <Flex
          flex={1}
          justify={"flex-end"}
          align={"center"}
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
        >
          <Icon as={ChevronRightIcon} color="Secondary.500" w={6} h={6} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileParent = ({ items }) => {
  return (
    <Stack display={{ lg: "none" }} bg="Neutral.900" px={7}>
      {items.map((item) => (
        <MobileChild key={item.label} {...item} />
      ))}
    </Stack>
  );
};

const MobileChild = ({ label, subLabel, children, path, icon }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("header");
  const { isOpen, onToggle } = useDisclosure();
  const { meritoSignedIn, firebaseUserSignOut } = useUserAuth();

  return (
    <Stack
      className={i18n.language === "jp" ? "jp" : "en"}
      onClick={children && onToggle}
      borderTop={1}
      borderStyle={"solid"}
      borderColor="Neutral.700"
    >
      <Flex
        onClick={() => {
          if (label === "sign_out") firebaseUserSignOut();
          navigate(path);
        }}
        as={Link}
        justify="space-between"
        _hover={{
          bg: "Neutral.700",
          textDecoration: "none",
        }}
        rounded="md"
        px={2}
        py={4}
      >
        <HStack align="center" spacing={6} color="Secondary.300" px={4} py={2}>
          <Icon w={5} h={5} as={icon} />
          <Text>{t(label)}</Text>
          {subLabel && (
            <Text color="Neutral.500" fontSize="sm" ml={2}>
              {subLabel}
            </Text>
          )}
          {/* </Flex> */}
        </HStack>
        {children && (
          <Icon
            as={ChevronDownIcon}
            w={7}
            h={7}
            color="Secondary.300"
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack align="start" pl={8} pb={4}>
          {children &&
            children.map((child) => (
              <Flex
                hidden={
                  ((child.label === "sign_out" || child.label === "portal") &&
                    !meritoSignedIn) ||
                  ((child.label === "sign_in" || child.label === "sign_up") &&
                    meritoSignedIn)
                }
                onClick={() => {
                  if (child.label === "sign_out") firebaseUserSignOut();
                  navigate(child.path);
                }}
                key={child.label}
                as={Link}
                w="full"
                justify="space-between"
                _hover={{
                  bg: "Neutral.800",
                  textDecoration: "none",
                }}
                rounded="md"
                px={2}
                py={3}
              >
                <HStack align="center" spacing={6}>
                  <Text color="Secondary.400" pl={7} py={2}>
                    {t(child.label)}
                  </Text>
                  {child.subLabel && (
                    <Text color="Neutral.500" fontSize="sm" ml={2}>
                      {child.subLabel}
                    </Text>
                  )}
                </HStack>
              </Flex>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default function Header() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("header");
  const { isOpen, onToggle } = useDisclosure();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const { currentMeritoUser, meritoSignedIn, firebaseUserSignOut } =
    useUserAuth();

  return (
    <Box position="sticky" top="0" zIndex="popover">
      <Flex
        align={"center"}
        bg="Neutral.900"
        minH={"60px"}
        py={{ base: 4 }}
        px={{ base: 8 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor="Neutral.700"
        boxShadow={"lg"}
        zIndex={0}
        position="relative"
      >
        {/* Menu Icon for Mobile */}
        <Flex display={{ base: "flex", lg: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={4} h={4} /> : <HamburgerIcon w={6} h={6} />
            }
            aria-label={"Toggle Items"}
            color="Neutral.100"
            _hover={{
              bg: "Neutral.700",
            }}
            variant={"ghost"}
            size="md"
            boxShadow="none"
          />
        </Flex>

        {/* Logo and Name */}
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", lg: "start" }}
          align={"center"}
        >
          <MeritoLogo
            className="header-logo"
            cursor={"pointer"}
            onClick={() => navigate("/")}
          />
          <Text
            textAlign="center"
            color="Neutral.0"
            fontSize={{ base: "2xl", md: "3xl", lg: "3xl" }}
            fontWeight={500}
            cursor={"pointer"}
            ml={2.5}
            onClick={() => navigate("/")}
          >
            Merito
          </Text>

          {/* Desktop Menu */}
          <Flex display={{ base: "none", lg: "flex" }} ml={10}>
            <DesktopParent items={DESKTOP_ITEMS} />
          </Flex>
        </Flex>

        {/* Logged-out */}
        <Stack
          className={i18n.language === "jp" ? "jp" : "en"}
          hidden={meritoSignedIn || isSmallScreen}
          flex={{ base: 1, lg: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={2}
        >
          <Button
            as={"a"}
            display={{ base: "inline-flex", lg: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.900"
            _hover={{
              bg: "ScienceDeals.600",
            }}
            boxShadow="none"
            size="md"
            cursor={"pointer"}
            onClick={() => navigate("/sign-in")}
          >
            {t("sign_in")}
          </Button>
          <Button
            as={"a"}
            display={{ base: "inline-flex", lg: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.600"
            _hover={{
              color: "Neutral.100",
              bg: "ScienceDeals.600",
            }}
            boxShadow="none"
            size="md"
            cursor={"pointer"}
            onClick={() => navigate("/sign-up")}
          >
            {t("sign_up")}
          </Button>
        </Stack>

        {/* Logged-in */}
        <Stack
          className={i18n.language === "jp" ? "jp" : "en"}
          hidden={!meritoSignedIn || isSmallScreen}
          flex={{ base: 1, lg: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={2}
        >
          <Button
            as={"a"}
            display={{ base: "inline-flex", lg: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.900"
            _hover={{
              bg: "Neutral.700",
            }}
            boxShadow="none"
            size="md"
            cursor={"pointer"}
            onClick={() => {
              firebaseUserSignOut();
              navigate("/");
            }}
          >
            {t("sign_out")}
          </Button>
          <Button
            as={"a"}
            display={{ base: "inline-flex", lg: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            bg="ScienceDeals.700"
            color="Neutral.200"
            _hover={{
              bg: "ScienceDeals.600",
              color: "Neutral.100",
            }}
            boxShadow="none"
            size="md"
            cursor={"pointer"}
            onClick={() => navigate("/portal")}
          >
            <Icon color="Neutral.100" w={5} h={5} as={IoPerson} />{" "}
            <Text as="span" ml={2}>
              {currentMeritoUser?.username}
            </Text>
          </Button>
        </Stack>

        {/* Language Picker */}
        <Stack ml={2}>
          <LanguagePicker />;
        </Stack>
      </Flex>

      {/* Mobile Menu */}
      <Collapse in={isOpen} animateOpacity>
        <MobileParent items={MOBILE_ITEMS} />
      </Collapse>
    </Box>
  );
}
