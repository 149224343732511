import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  Link,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoDotFill } from "react-icons/go";

import image_buyers_creators from "../assets/buyers_creators.png";
import image_2_sides from "../assets/2_sides.png";
import image_comparison from "../assets/comparison.png";

export default function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("home");

  return (
    <Flex
      className={i18n.language === "jp" ? "jp" : "en"}
      w="full"
      direction="column"
      justifyContent="center"
      alignItems="center"
      fontWeight={500}
      py={{ base: 12, sm: 20, md: 28 }}
    >
      {/* Heading */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="start"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Text
          color="Neutral.0"
          fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
          fontWeight={600}
        >
          {t("heading_1")}
        </Text>
        <Text
          color="Neutral.0"
          fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
          fontWeight={600}
        >
          {t("heading_2")}
        </Text>
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Subheading - 1 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="start"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }}>
          {t("subheading_1")}
        </Text>
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Paragraph - 1 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Flex
          w="full"
          direction="column"
          justifyContent="center"
          alignItems="start"
          bg="ScienceDeals.800"
          borderRadius="lg"
          px={{ base: 6, sm: 9, md: 12, lg: 16 }}
          py={{ base: 4, sm: 7, md: 10, lg: 14 }}
        >
          <Text color="Secondary.300" fontSize={{ base: "xl", md: "2xl" }}>
            {t("paragraph_1", "")}
          </Text>
          <br />
          <Text color="Neutral.50" fontSize={{ base: "lg", md: "xl" }}>
            {t("paragraph_2", "")}
          </Text>
          <Text color="Neutral.50" fontSize={{ base: "lg", md: "xl" }}>
            {t("paragraph_3", "")}
          </Text>
          <br />
          <Text color="Neutral.50" fontSize={{ base: "lg", md: "xl" }}>
            {t("paragraph_4", "")}
          </Text>
          <Text color="Neutral.50" fontSize={{ base: "lg", md: "xl" }}>
            {t("paragraph_5", "")}
          </Text>
        </Flex>
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Buttons - 1 */}
      <Flex
        w="full"
        direction={{ base: "column", sm: "row" }}
        justifyContent="space-evenly"
        alignItems="center"
        gap={{ base: 6, sm: 4 }}
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Button
          w={{ base: 190, md: 220 }}
          variant="NeutralSecondary"
          color="Secondary.200"
          fontSize={{ base: "lg", md: "xl" }}
          borderRadius="xl"
          py={{ base: 7, md: 7 }}
          onClick={() => navigate("/science-deals/listing")}
        >
          {t("button_browse_projects")}
        </Button>
        <Button
          w={{ base: 190, md: 220 }}
          variant="NeutralSecondary"
          color="Secondary.200"
          fontSize={{ base: "lg", md: "xl" }}
          borderRadius="xl"
          py={{ base: 7, md: 7 }}
          onClick={() => navigate("/science-deals/project-creation")}
        >
          {t("button_create_project")}
        </Button>
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Subheading - 2 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }}>
          {t("subheading_2")}
        </Text>
      </Flex>

      {/* Image - 1 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        pt={12}
      >
        <Image
          src={image_buyers_creators}
          w="full"
          maxW="900px"
          objectFit="contain"
          borderRadius="sm"
        />
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Paragraph - 2 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="start"
          borderRadius="lg"
        >
          <Text
            color="Secondary.300"
            fontSize={{ base: "xl", md: "2xl" }}
            textDecoration="underline"
          >
            {t("paragraph_6")}
          </Text>
          <br />
          <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }}>
            {t("paragraph_7")}
          </Text>
          <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }} pl={4}>
            <Icon
              as={GoDotFill}
              w={{ base: 3, md: 4 }}
              h={{ base: 3, md: 4 }}
              mr={{ base: 2, md: 3 }}
            />
            {t("paragraph_8")}
          </Text>
          <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }} pl={4}>
            <Icon
              as={GoDotFill}
              w={{ base: 3, md: 4 }}
              h={{ base: 3, md: 4 }}
              mr={{ base: 2, md: 3 }}
            />
            {t("paragraph_9")}
          </Text>
        </Flex>
      </Flex>

      {/* Image - 2 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        pt={12}
      >
        <Image
          src={image_2_sides}
          w="full"
          maxW="700px"
          objectFit="contain"
          borderRadius="sm"
        />
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Subheading - 3 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Text color="Neutral.50" fontSize={{ base: "xl", md: "2xl" }}>
          {t("subheading_3")}
        </Text>
      </Flex>

      {/* Image - 3 */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        pt={12}
      >
        <Image
          src={image_comparison}
          w="full"
          maxW="850px"
          objectFit="contain"
          borderRadius="sm"
        />
      </Flex>

      {/* Divider */}
      <Flex
        w="full"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 8, sm: 14, md: 24 }}
        py={{ base: 12, md: 16 }}
      >
        <Divider borderColor="Neutral.600" borderWidth="1px" />
      </Flex>

      {/* Buttons - 2 */}
      <Flex
        w="full"
        direction={{ base: "column", sm: "row" }}
        justifyContent="space-evenly"
        alignItems="center"
        gap={{ base: 6, sm: 4 }}
        px={{ base: 8, sm: 14, md: 24 }}
      >
        <Button
          w={{ base: 190, md: 220 }}
          variant="NeutralSecondary"
          color="Secondary.200"
          fontSize={{ base: "lg", md: "xl" }}
          borderRadius="xl"
          py={{ base: 7, md: 7 }}
          onClick={() => navigate("/sign-up")}
        >
          {t("button_join_merito")}
        </Button>
        <Button
          w={{ base: 190, md: 220 }}
          variant="NeutralSecondary"
          color="Secondary.200"
          fontSize={{ base: "lg", md: "xl" }}
          borderRadius="xl"
          py={{ base: 7, md: 7 }}
          onClick={() => navigate("/contact")}
        >
          {t("button_contact")}
        </Button>
      </Flex>
    </Flex>
  );
}
