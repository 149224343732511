import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Container,
  Checkbox,
  Link,
  Textarea,
  Button,
  Text,
  Stack,
  VStack,
  HStack,
  InputGroup,
  InputLeftAddon,
  Select,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";
import DisclaimerModal from "./DisclaimerModal";
import {
  getCurrentJSTDateTime,
  parseNumber,
  formatNumber,
  trimSpaces,
  isAlphaNumericOrSymbol,
  isValidEmail,
} from "../utilities/utilities";
import {
  emptyFieldError,
  projectNameExistsError,
  formIncompleteMessage,
  projectCreated,
  projectCreationFailed,
} from "../utilities/messages";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_PROJECT_CREATION = "/science_deals/project";
const ENDPOINT_DUPLICATE_CHECK = "/duplicate_check";

export default function ScienceDealsProjectCreation() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t, i18n } = useTranslation("project_creation");
  const { setIsLoading } = useLoading();
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const [currentStep, setCurrentStep] = useState(1);
  const [projectForm, setProjectForm] = useState({
    project_name: null,
    project_owner: null,
    organization: null,
    jurisdiction: null,
    project_description: null,
    project_goal: null,
    project_start_date: null,
    project_duration: null,
    project_cost_currency: null,
    project_cost: null,
    project_image: null,
    project_document: null,
    disclaimer_checked_at: null,
  });
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [formattedNumbers, setFormattedNumbers] = useState({
    project_cost: null,
  });
  const [formErrorMessages, setFormErrorMessages] = useState(() => {
    // Same set of fields as projectForm, but withouth disclaimer_checked_at
    const { disclaimer_checked_at, ...rest } = projectForm;
    return { ...rest };
  });

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  const handleNext = () => {
    checkSignInStatus();

    const nextStep = currentStep === 5 ? currentStep : currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handleBack = () => {
    checkSignInStatus();

    const prevStep = currentStep === 1 ? currentStep : currentStep - 1;
    setCurrentStep(prevStep);
  };

  const handleInputChange = (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value, files } = event.target;

    if (name === "project_cost") {
      // Update both formatted and form values
      setFormattedNumbers({
        ...formattedNumbers,
        // Keep the raw value here (onChange) to let users type values like 1,000, 10.00, etc.
        [name]: value,
      });
      setProjectForm({ ...projectForm, [name]: parseNumber(value) });
    } else if (["project_image", "project_document"].includes(name)) {
      setProjectForm({ ...projectForm, [name]: files[0] });
    } else {
      setProjectForm({ ...projectForm, [name]: value });
    }
  };

  const handleInputValidation = async (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value, files } = event.target;
    let input;

    if (name === "project_name") {
      input = trimSpaces(value);
      let isDuplicate = false;
      // Update the value in the form
      setProjectForm({ ...projectForm, [name]: input });

      // Check if the project name already exists
      if (input) {
        console.log(`Calling Merito API: ${ENDPOINT_DUPLICATE_CHECK}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.get(
          `${MERITO_API_HOST}${ENDPOINT_DUPLICATE_CHECK}?project_name=${input}`,
          authHeader
        );
        isDuplicate = response.data.content.project_name;
      }

      // Set error message if the input is not unique or empty/null
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null || input == undefined
            ? emptyFieldError[i18n.language]
            : isDuplicate
            ? projectNameExistsError[i18n.language]
            : null,
      });
      return;
    } else if (
      [
        "project_owner",
        "organization",
        "project_description",
        "project_goal",
      ].includes(name)
    ) {
      input = trimSpaces(value);
    } else if (
      [
        "jurisdiction",
        "project_start_date",
        "project_duration",
        "project_cost_currency",
      ].includes(name)
    ) {
      input = value;
    } else if (name === "project_cost") {
      input = parseNumber(value);

      // Update formatted string
      setFormattedNumbers({
        ...formattedNumbers,
        [name]: formatNumber(input),
      });
    } else if (["project_image", "project_document"].includes(name)) {
      input = files[0];
    }

    // Update the value in the form
    setProjectForm({ ...projectForm, [name]: input });

    // Set error message if the input is empty/null
    setFormErrorMessages({
      ...formErrorMessages,
      [name]:
        input === "" || input === null || input == undefined
          ? emptyFieldError[i18n.language]
          : null,
    });
  };

  const handleDisclaimerCheck = () => {
    checkSignInStatus();
    const previousCheckStatus = disclaimerChecked;

    let currentJSTDateTime = null;
    if (!previousCheckStatus) {
      currentJSTDateTime = getCurrentJSTDateTime();
    }

    setProjectForm({
      ...projectForm,
      disclaimer_checked_at: currentJSTDateTime,
    });
    setDisclaimerChecked(!previousCheckStatus);
  };

  const checkFieldStatus = () => {
    // Copy the current error messages
    const newErrorStatus = { ...formErrorMessages };

    Object.entries(newErrorStatus).forEach(([field, message]) => {
      const value = projectForm[field];

      // If the field already has an error message, skip the check
      if (message !== null) return;

      // Check if the value is null and update the error message
      newErrorStatus[field] =
        value === null ? emptyFieldError[i18n.language] : null;
    });

    // Set the updated error messages after the iteration
    setFormErrorMessages(newErrorStatus);

    // Return true if there are still error messages
    const hasIncompleteFields = Object.values(newErrorStatus).some(
      (message) => message !== null
    );
    return hasIncompleteFields;
  };

  const handleProjectCreation = async () => {
    checkSignInStatus();
    setIsLoading(true);

    // First check if any fields still have error messages or are empty/null
    const hasIncompleteFields = checkFieldStatus();
    if (hasIncompleteFields) {
      setIsLoading(false);
      onClose();
      setCurrentStep(1);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: formIncompleteMessage[i18n.language],
      });
      return;
    }

    try {
      // Use FormData for File Uploads
      const projectFormData = new FormData();
      projectFormData.append("project_name", projectForm.project_name);
      projectFormData.append("project_owner", projectForm.project_owner);
      projectFormData.append("organization", projectForm.organization);
      projectFormData.append("jurisdiction", projectForm.jurisdiction);
      projectFormData.append(
        "project_description",
        projectForm.project_description
      );
      projectFormData.append("project_goal", projectForm.project_goal);
      projectFormData.append(
        "project_start_date",
        projectForm.project_start_date
      );
      projectFormData.append("project_duration", projectForm.project_duration);
      projectFormData.append(
        "project_cost_currency",
        projectForm.project_cost_currency
      );
      projectFormData.append("project_cost", projectForm.project_cost);
      projectFormData.append("project_image", projectForm.project_image);
      projectFormData.append("project_document", projectForm.project_document);
      projectFormData.append(
        "disclaimer_checked_at",
        projectForm.disclaimer_checked_at
      );
      projectFormData.append("user_id_seller", currentMeritoUser.user_id);
      projectFormData.append("user_id_buyer", null);

      console.log(`Calling Merito API: ${ENDPOINT_PROJECT_CREATION}`);
      const authHeader = await firebaseBuildAuthHeader();
      const response = await axios.post(
        `${MERITO_API_HOST}${ENDPOINT_PROJECT_CREATION}`,
        projectFormData,
        {
          headers: {
            ...authHeader.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const project = response.data.content;

      console.log("Project created:\n", project);
      toast({
        status: "success",
        isClosable: true,
        title: "Success",
        description: projectCreated[i18n.language],
      });

      navigate("/science-deals/sra-generation", {
        state: { project_id: project.project_id },
      });
    } catch (err) {
      const errMsg = "Failed to create Science Deals project:\n";
      console.error(errMsg, err);
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: projectCreationFailed[i18n.language],
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex
      w="full"
      maxW="1200px"
      h="80vh"
      minH="560px"
      direction={{ base: "column", lg: "row" }}
      justifyContent="center"
      alignItems="center"
      my={{ base: 24, md: 10 }}
      p={6}
    >
      {/* Form Left/Top Side */}
      <VStack
        w={{ base: "full", lg: "40%" }}
        h={{ base: "max-content", lg: "full" }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        overflowWrap="break-word"
        bg="ScienceDeals.700"
        color="Neutral.50"
        borderTopLeftRadius="xl"
        borderTopRightRadius={{ base: "xl", lg: "none" }}
        borderBottomLeftRadius={{ base: "none", lg: "xl" }}
        boxShadow="2xl"
        px={6}
        py={{ base: 10, sm: 12 }}
      >
        <Text fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}>
          Science Deals
        </Text>
        <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
          Make science funding more{" "}
          <Text as="span" textDecoration="underline">
            merito
          </Text>
          cratic.
        </Text>
      </VStack>

      {/* Form Right/Bottom Side */}
      <VStack
        className={i18n.language === "jp" ? "jp" : "en"}
        w={{ base: "full", lg: "60%" }}
        h={{ base: "full", lg: "full" }}
        justifyContent="space-between"
        alignItems="left"
        overflowWrap="break-word"
        bg="Neutral.0"
        color="ScienceDeals.600"
        borderBottomRightRadius="xl"
        borderTopRightRadius={{ base: "none", lg: "xl" }}
        borderBottomLeftRadius={{ base: "xl", lg: "none" }}
        boxShadow="2xl"
        px={8}
        py={{ base: 10, lg: 12 }}
      >
        <VStack
          justifyContent="start"
          alignItems="left"
          overflowX="scroll"
          overflowY="scroll"
          spacing={{ base: 7, lg: 10 }}
        >
          {/* Title */}
          <Text
            textAlign="center"
            color="ScienceDeals.500"
            fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
            fontWeight={500}
            position="sticky"
            top="0"
          >
            {t("title")}
          </Text>

          {/* Project Creation Form */}
          <VStack
            overflowX="scroll"
            overflowY="scroll"
            textAlign="left"
            spacing={5}
          >
            {/* Form */}
            <FormControl
              id="project_name"
              isRequired
              isInvalid={formErrorMessages.project_name}
              hidden={currentStep !== 1}
            >
              <FormLabel>
                {t("project_name")}
                <Tooltip
                  label={t("project_name_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="text"
                name="project_name"
                value={projectForm.project_name}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("project_name_placeholder")}
              />
              <FormErrorMessage>
                {formErrorMessages.project_name}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_owner"
              isRequired
              isInvalid={formErrorMessages.project_owner}
              hidden={currentStep !== 1}
            >
              <FormLabel>
                {t("project_owner")}
                <Tooltip
                  label={t("project_owner_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="text"
                name="project_owner"
                value={projectForm.project_owner}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("project_owner_placeholder")}
              />
              <FormErrorMessage>
                {formErrorMessages.project_owner}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="organization"
              isRequired
              isInvalid={formErrorMessages.organization}
              hidden={currentStep !== 1}
            >
              <FormLabel>
                {t("organization")}
                <Tooltip
                  label={t("organization_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="text"
                name="organization"
                value={projectForm.organization}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("organization_placeholder")}
              />
              <FormErrorMessage>
                {formErrorMessages.organization}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="jurisdiction"
              isRequired
              isInvalid={formErrorMessages.jurisdiction}
              hidden={currentStep !== 1}
            >
              <FormLabel>
                {t("jurisdiction")}
                <Tooltip
                  label={t("jurisdiction_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Select
                name="jurisdiction"
                value={projectForm.jurisdiction}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                defaultValue="Select option"
              >
                <option value="Select option" disabled>
                  {t("select_option")}
                </option>
                <option value="United States">{t("option_us")}</option>
                <option value="Japan">{t("option_jp")}</option>
              </Select>
              <FormErrorMessage>
                {formErrorMessages.jurisdiction}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_description"
              isRequired
              isInvalid={formErrorMessages.project_description}
              hidden={currentStep !== 2}
            >
              <FormLabel>
                {t("project_description")}
                <Tooltip
                  label={t("project_description_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Textarea
                name="project_description"
                value={projectForm.project_description}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("project_description_placeholder")}
                size="md"
              />
              <FormErrorMessage>
                {formErrorMessages.project_description}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_goal"
              isRequired
              isInvalid={formErrorMessages.project_goal}
              hidden={currentStep !== 2}
            >
              <FormLabel>
                {t("project_goal")}
                <Tooltip
                  label={t("project_goal_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Textarea
                name="project_goal"
                value={projectForm.project_goal}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("project_goal_placeholder")}
                size="md"
              />
              <FormErrorMessage>
                {formErrorMessages.project_goal}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_start_date"
              isRequired
              isInvalid={formErrorMessages.project_start_date}
              hidden={currentStep !== 3}
            >
              <FormLabel>
                {t("project_start_date")}
                <Tooltip
                  label={t("project_start_date_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="date"
                name="project_start_date"
                value={projectForm.project_start_date}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
              />
              <FormErrorMessage>
                {formErrorMessages.project_start_date}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_duration"
              isRequired
              isInvalid={formErrorMessages.project_duration}
              hidden={currentStep !== 3}
            >
              <FormLabel>
                {t("project_duration")}
                <Tooltip
                  label={t("project_duration_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Select
                name="project_duration"
                value={projectForm.project_duration}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                defaultValue="Select option"
              >
                <option value="Select option" disabled>
                  {t("select_option")}
                </option>
                <option value="1">1. &nbsp; {t("option_1")}</option>
                <option value="2">2. &nbsp; {t("option_2")}</option>
                <option value="3">3. &nbsp; {t("option_3")}</option>
                <option value="4">4. &nbsp; {t("option_4")}</option>
                <option value="5">5. &nbsp; {t("option_5")}</option>
                <option value="6">6. &nbsp; {t("option_6")}</option>
              </Select>
              <FormErrorMessage>
                {formErrorMessages.project_duration}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_cost"
              isRequired
              isInvalid={
                formErrorMessages.project_cost_currency ||
                formErrorMessages.project_cost
              }
              hidden={currentStep !== 3}
            >
              <FormLabel>
                {t("project_cost")}
                <Tooltip
                  label={t("project_cost_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <FormHelperText>{t("currency")}</FormHelperText>
              <Select
                name="project_cost_currency"
                value={projectForm.project_cost_currency}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                defaultValue="Select option"
                mt={2}
              >
                <option value="Select option" disabled>
                  {t("select_option")}
                </option>
                <option value="USD">{t("option_usd")}</option>
                <option value="JPY">{t("option_jpy")}</option>
              </Select>
              <FormErrorMessage>
                {formErrorMessages.project_cost_currency}
              </FormErrorMessage>

              <FormHelperText>
                {t("cost")}
                {i18n.language === "jp" && (
                  <Tooltip
                    label={t("cost_tip")}
                    bg="Neutral.300"
                    color="Neutral.0"
                    fontSize="sm"
                    borderRadius="md"
                    px={4}
                    py={3}
                  >
                    <InfoIcon
                      ml={2}
                      mr={1}
                      mb={1}
                      color="Neutral.300"
                      _hover={{ color: "Neutral.500" }}
                    />
                  </Tooltip>
                )}
              </FormHelperText>
              <Input
                type="text"
                name="project_cost"
                value={formattedNumbers.project_cost}
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                placeholder={t("cost_placeholder")}
                mt={2}
              />
              <FormErrorMessage>
                {formErrorMessages.project_cost}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_image"
              isRequired
              isInvalid={formErrorMessages.project_image}
              hidden={currentStep !== 4}
            >
              <FormLabel>
                {t("project_image")}
                <Tooltip
                  label={t("project_image_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="file"
                name="project_image"
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                mt={2}
                p={1}
              />
              <FormErrorMessage>
                {formErrorMessages.project_image}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="project_document"
              isRequired
              isInvalid={formErrorMessages.project_document}
              hidden={currentStep !== 4}
            >
              <FormLabel>
                {t("project_document")}
                <Tooltip
                  label={t("project_document_tip")}
                  bg="Neutral.300"
                  color="Neutral.0"
                  fontSize="sm"
                  borderRadius="md"
                  px={4}
                  py={3}
                >
                  <InfoIcon
                    ml={2}
                    mr={1}
                    mb={1}
                    color="Neutral.300"
                    _hover={{ color: "Neutral.500" }}
                  />
                </Tooltip>
              </FormLabel>
              <Input
                type="file"
                name="project_document"
                onChange={handleInputChange}
                onBlur={async (event) => await handleInputValidation(event)}
                mt={2}
                p={1}
              />
              <FormErrorMessage>
                {formErrorMessages.project_document}
              </FormErrorMessage>
            </FormControl>

            <Checkbox
              id="disclaimerChecked"
              name="disclaimerChecked"
              value={disclaimerChecked}
              onChange={handleDisclaimerCheck}
              mt={10}
              hidden={currentStep !== 5}
              size={"lg"}
            >
              <Text fontWeight={400} fontSize="lg" ml={4}>
                {t("agreement_1")}
                <Link
                  color="Secondary.300"
                  href="/terms-and-conditions"
                  isExternal
                >
                  {t("terms_and_conditions")}
                </Link>
                {t("agreement_2")}
                <Link color="Secondary.300" href="/privacy-policy" isExternal>
                  {t("privacy_policy")}
                </Link>
                {t("agreement_3")}
              </Text>
            </Checkbox>

            {/* Modal for disclaimer and form submission */}
            <Box>
              <Button
                isDisabled={!disclaimerChecked}
                hidden={currentStep !== 5}
                variant="ScienceDeals"
                mt={12}
                onClick={onOpen}
              >
                {t("create")}
              </Button>
              <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay bg="blackAlpha.50" backdropFilter="blur(7px)" />
                <ModalContent boxShadow="2xl" mx={6}>
                  <DisclaimerModal
                    onClose={onClose}
                    handleProjectCreation={handleProjectCreation}
                  />
                </ModalContent>
              </Modal>
            </Box>
          </VStack>
        </VStack>

        {/* Back / Next Buttons */}
        <HStack justifyContent="space-between" mt={8}>
          <Button
            isDisabled={currentStep === 1}
            w={{ base: "88px", md: "100px" }}
            fontSize={{ base: "md", md: "lg" }}
            variant="OrangeSecondary"
            onClick={handleBack}
          >
            {t("back")}
          </Button>
          <Text as="span" color="Neutral.400" fontSize="md" fontWeight={500}>
            {currentStep} / 5
          </Text>
          <Button
            isDisabled={currentStep === 5}
            w={{ base: "88px", md: "100px" }}
            fontSize={{ base: "md", md: "lg" }}
            variant="OrangeSecondary"
            onClick={handleNext}
          >
            {t("next")}
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
}
