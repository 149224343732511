import React from "react";
import { Icon, Text, Flex, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";

export const PDFViewer = ({ project_document_url }) => {
  return (
    <LinkBox
      as="article"
      maxW="sm"
      alignItems="center"
      alignContent="center"
      color="ScienceDeals.300"
      fontWeight={500}
      fontSize={{ base: "lg", md: "xl" }}
    >
      <Flex justifyContent="start" alignItems="center">
        <Icon as={FaFilePdf} w={7} h={7} />
        <Text ml={2}>View Project PDF</Text>{" "}
      </Flex>
      <LinkOverlay
        href={project_document_url}
        isExternal
        target="_blank"
        rel="noopener noreferrer"
      />
    </LinkBox>
  );
};
