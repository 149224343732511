import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
} from "@chakra-ui/react";
import { IoLanguage } from "react-icons/io5";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function LanguagePicker() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        display="flex"
        iconSpacing={0}
        leftIcon={
          <Icon
            as={MdLanguage}
            w={{ base: "5", md: "6" }}
            h={{ base: "5", md: "6" }}
          />
        }
        rightIcon={
          <Icon
            as={IoLanguage}
            w={{ base: "5", md: "6" }}
            h={{ base: "5", md: "6" }}
          />
        }
        w={16}
        bg="Neutral.100"
        color="Neutral.500"
        _hover={{
          color: "Neutral.700",
        }}
        _active={{
          color: "Neutral.700",
        }}
        size="md"
        boxShadow="none"
      />
      <MenuList bg="Neutral.100">
        <MenuItem
          bg="Neutral.100"
          color="Neutral.700"
          fontSize={{ base: "md", md: "lg" }}
          _hover={{
            bg: "Neutral.200",
            color: "Neutral.800",
          }}
          onClick={() => changeLanguage("en")}
        >
          English
        </MenuItem>
        <MenuItem
          bg="Neutral.100"
          color="Neutral.700"
          fontSize={{ base: "md", md: "lg" }}
          _hover={{
            bg: "Neutral.200",
            color: "Neutral.800",
          }}
          onClick={() => changeLanguage("jp")}
        >
          日本語
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
