import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Stack,
  Icon,
  Divider,
  AspectRatio,
  useToast,
} from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";
import {
  projectListingLoadFailed,
  isLoadingProjectListing,
  noProjectsListed,
} from "../utilities/messages";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_PROJECTS = "/science_deals/projects";

export default function ScienceDealsListing() {
  const navigate = useNavigate();
  const toast = useToast();
  const { i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const { firebaseBuildAuthHeader } = useUserAuth();

  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);

  useEffect(() => {
    console.log("Projects:", projects);
  }, [projects]);

  useEffect(() => {
    const getProjects = async () => {
      setIsLoading(true);
      setIsLoadingProjects(true);

      try {
        console.log(`Calling Merito API: ${ENDPOINT_PROJECTS}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.get(
          `${MERITO_API_HOST}${ENDPOINT_PROJECTS}`,
          authHeader
        );

        setProjects(response.data.content);
        console.log("Projects retrieved");
      } catch (err) {
        const errMsg = "Failed to retrieve projects:\n";
        console.error(errMsg, err);
        toast({
          status: "error",
          isClosable: true,
          title: "Error",
          description: projectListingLoadFailed[i18n.language],
        });
      } finally {
        // Data fetching is complete
        setIsLoadingProjects(false);
        setIsLoading(false);
      }
    };

    getProjects();
  }, []);

  return (
    <>
      {/* Title */}
      <Stack w="full" textAlign="center" my={{ base: 10, md: 14 }} px={8}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="Neutral.100"
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          px={{ base: 4, md: 0 }}
          mb={8}
        >
          <Text whiteSpace="normal" overflowWrap="break-word">
            Science Deals Projects
          </Text>
        </Box>
        <Divider
          orientation="horizontal"
          borderColor="Neutral.600"
          borderWidth="1px"
        />
      </Stack>
      {/* Ensure projects are loaded before rendering */}
      {!isLoadingProjects && projects.length !== 0 ? (
        <SimpleGrid
          columns={{ sm: 2, md: 3, lg: 4 }}
          spacing={10}
          p={8}
          mt={-8}
        >
          {projects.map((project) => (
            // Project Grid
            <Box
              key={project.project_id}
              role="group"
              maxW="sm"
              overflow="hidden"
              bg="Neutral.50"
              borderWidth="1.5px"
              borderColor="Neutral.700"
              rounded="xl"
              boxShadow="2xl"
              _hover={{
                boxShadow: "xl",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/science-deals/project/${project.project_id}`, {
                  state: { project },
                });
              }}
            >
              <AspectRatio ratio={1}>
                <Image
                  w="full"
                  h="full"
                  objectFit="cover"
                  src={project.project_image_url}
                  alt={`Project titled: ${project.project_name}`}
                  loading="lazy"
                  fallback={
                    <Icon
                      as={MdImageNotSupported}
                      boxSize={12}
                      color="gray.500"
                    />
                  }
                  bg="Neutral.300"
                  transition="0.2s ease-in-out"
                  _groupHover={{ filter: "brightness(1.1)", shadow: "2xl" }}
                />
              </AspectRatio>
              <Box borderTopWidth="1.5px" borderColor="Neutral.400" p="6">
                <Text
                  isTruncated
                  as="h4"
                  color="ScienceDeals.500"
                  fontWeight={600}
                  lineHeight="tight"
                  _groupHover={{ color: "ScienceDeals.600" }}
                  mt="0"
                >
                  {project.project_name}
                </Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Text color="Neutral.500" fontSize={{ base: "xl", md: "2xl" }} mt="5vh">
          {!isLoadingProjects && projects.length === 0
            ? noProjectsListed[i18n.language]
            : isLoadingProjectListing[i18n.language]}
        </Text>
      )}
      ;
    </>
  );
}
