import {
  Box,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  createIcon,
} from "@chakra-ui/react";

import "../App.css";
import { ReactComponent as MeritoLogo } from "../assets/logo_header.svg";

export default function Placeholder() {
  return (
    <Box>
      <Container>
        <Stack as={Box} spacing={{ base: 8, md: 14 }} pt={{ base: 20, md: 32 }}>
          <Text
            textAlign="center"
            fontSize={{ base: "4xl", md: "5xl" }}
            color="ScienceDeals.500"
          >
            Coming Soon.
          </Text>
        </Stack>
        <Stack alignItems="center" pt={{ base: 10, md: 12, lg: 20 }}>
          <MeritoLogo
            fill="#DFEBE7"
            stroke="#DFEBE7"
            className="logo-rotation logo-size"
          />
        </Stack>
      </Container>
    </Box>
  );
}
