import React from "react";
import { Text } from "@chakra-ui/react";

import DocumentViewer from "./DocumentViewer";

const TITLE = "privacy_policy";

const CONTENT = `
<!DOCTYPE html>
<html lang="ja">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {{
      font-family: Arial, sans-serif;
    }}
    .document-title {{
      // h1
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 20px;
    }}
    .subtitle {{
      // h2
      font-weight: bold;
      margin-bottom: 20px;
    }}
    .article-title {{
      // h3
      text-decoration: underline;
      margin-top: 15px;
    }}
    .closing {{
      // p
      text-align: right;
      margin-top: 40px;
    }}
    .bold {{
      // span
      font-weight: bold;
    }}
    .indentation-1 {{
      // div
      margin-left: 20px;
    }}
    .indentation-2 {{
      // div
      margin-left: 40px;
    }}
  </style>
</head>
<body>
  <h1 class="document-title">プライバシーポリシー</h1>

  <p>株式会社●（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。</p>

  <h3 class="article-title">1. 利用者情報その他の個人情報の取得及び利用目的</h3>
  <p>
    1.1 利用者（当社のサービス（以下「当社サービス」という。）の利用者となる個人又は当社との間で、当社サービスを利用するための契約を締結した法人をいいます。）から取得する利用者情報（利用者から提供を受ける個人情報及び個人情報に該当しない個人に関する情報の総称を意味します。以下同じです。）及び取得方法は、以下のとおりです。
    <div class="indentation-1">(1) 利用者から直接ご提供いただく情報</div>
    <div class="indentation-2">1 ご登録又はご利用の際にご入力いただく情報（氏名、住所、電話番号、メールアドレス、利用者番号等。ログイン機能がある場合には、ログインにより確認される利用者の情報を含みます。）</div>
    <div class="indentation-2">2 当社が提供する「●」という名称の当社が提供するサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含み、以下総称して「当社サービス」といいます。）のご利用に関する情報（当社サービスの利用状況、調査・アンケート結果等の情報）</div>
    <div class="indentation-2">3 ご利用の電子機器、端末等から自動的に収集する情報（Cookie、端末識別子、端末情報、ブラウザの種類・バージョン、オペレーションシステム、IPアドレス、利用履歴、閲覧履歴、広告接触ログデータ、ウェブサイト上の行動履歴、位置情報データ等の情報）</div>
    <div class="indentation-2">4 利用者のウォレットアドレス</div>
    <div class="indentation-2">5 その他、利用者が任意で提供した情報</div>

    <div class="indentation-1">(2) 利用者が利用する当社以外の他のサービスの提供者等から間接的にご提供いただく情報及び公開されている情報</div>
    <div class="indentation-2">1 当社以外の他の事業者が提供しているサービスのご利用に関する情報（Cookie、端末識別子、端末情報、ブラウザの種類・バージョン、オペレーションシステム、IPアドレス、利用者ID、利用履歴、閲覧履歴、広告接触ログデータ、ウェブサイト上の行動履歴、位置情報データ等の情報）</div>
    <div class="indentation-2">2 ブロックチェーン上に公開されている情報</div>
    <div class="indentation-2">3 上記のほか、当社への提供につき、別途利用者の同意を得た情報</div>

    <div class="indentation-1">(3) 情報収集モジュールを通じて取得する情報</div>
    <div class="indentation-2">利用者の属性情報、行動履歴、ログ情報、クラッシュ情報、端末・ネットワーク情報その他利用に関する情報</div>
    <div class="indentation-2">なお、「情報収集モジュール」とは、広告表示やアプリケーションの利用状態、利用頻度等を解析するための、独立した当社以外の第三者が提供するプログラムをいいます。</div>
    
    <div class="indentation-1">(4) 取引先、当社の役職員及び株主等から取得する情報</div>
    <div class="indentation-2">1 当社の取引先の担当者の氏名、メールアドレス、電話番号等の個人情報</div>
    <div class="indentation-2">2 役職員及び応募者の氏名、住所、メールアドレス、電話番号等の個人情報</div>
    <div class="indentation-2">3 株主、新株予約権者等の氏名、住所、メールアドレス、電話番号等の個人情報</div>      
  </P>
  <p>
    1.2 当社は、利用者情報を以下の目的で利用いたします。
    <div class="indentation-1">(1) 当社サービスの提供（ログイン機能提供（本人認証、入力補助、接続状態の維持等）、利用資格の確認、サービス間の連携、イベントの管理運営、アンケートのご依頼、代金決済、アフターサービスを含みます。）並びに役務の提供のため</div>
    <div class="indentation-1">(2) 当社サービスに関するご案内、お問い合わせ（お問い合わせの保管管理を含みます。）等への対応のため</div>
    <div class="indentation-1">(3) 当社のサービス等のご案内（メールマガジンの配信、ダイレクトメール送付を含みます。）のため</div>
    <div class="indentation-1">(4) 当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</div>
    <div class="indentation-1">(5) 当社サービスに関する規約等の変更などを通知するため</div>
    <div class="indentation-1">(6) 業務上の連絡・対応、契約関係の管理のため</div>
    <div class="indentation-1">(7) 当社サービスのユーザーの各サービスの利用の状況等に関する情報を分析することによる当社サービスの改善、新サービスの開発等</div>
    <div class="indentation-1">(8) 利用者情報に関する業務処理等受託業務遂行のため</div>
    <div class="indentation-1">(9) 不正行為等の調査、防止及び対応のため</div>
    <div class="indentation-1">(10) 当社サービスに関連して、個人を識別できない形式に加工した統計データを作成するため</div>
    <div class="indentation-1">(11) その他、上記利用目的に付随する目的のため</div>
  </p>
  <p>
    1.3 当社は、当社の取引先、当社の役職員及び株主等から取得する情報を以下の目的で利用いたします。
    <div class="indentation-1">(1) 取引先の管理及び取引先への連絡のため</div>
    <div class="indentation-1">(2) 雇用管理及び社内手続のため、人材採用活動における選考及び連絡のため</div>
    <div class="indentation-1">(3) 株主管理、会社法その他法令上の手続対応のため</div>
    <div class="indentation-1">(4) その他、上記利用目的に付随する目的のため</div>
  </p>


  <h3 class="article-title">2. 利用者情報その他の個人情報の利用</h3>
  <p>2.1 当社は、個人情報保護法その他の法令により許容される場合を除き、利用者情報その他の個人情報の主体である個人（以下「本人」といいます。）の同意を得ず、利用目的の達成に必要な範囲を超えて利用者情報その他の個人情報を取り扱いません。</p>
  <p>2.2 当社は、違法又は不当な行為を助長し、又は誘発するおそれがある方法により利用者情報その他の個人情報を利用しません。</p>


  <h3 class="article-title">3. 利用者情報その他の個人情報の適正な取得</h3>
  <p>当社は、適正に利用者情報その他の個人情報を取得し、偽りその他不正の手段により取得しません。</p>


  <h3 class="article-title">4. 個人情報の安全管理</h3>
  <p>当社は、個人情報（当社が取得しようとしている個人情報を含みます。）の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。当社の保有個人データに関する安全管理措置の概要は、組織体制の整備、個人情報取扱台帳の整備、定期点検・監査、従業者の教育、不正アクセス等の防止、外的環境の把握等となりますが、具体的な安全管理措置の内容は、本人から第7項のお問い合わせ窓口よりご連絡頂けましたら、遅滞なく回答いたします。</p>


  <h3 class="article-title">5. 第三者提供</h3>
  <p>当社は、個人情報保護法で許容される場合を除き、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。</p>


  <h3 class="article-title">6. 個人情報等の開示、訂正等、利用停止等</h3>
  <p>当社は、本人から、個人情報保護法の定めに基づき、個人情報及び第三者提供記録の開示、個人情報の訂正、追加又は削除（以下「訂正等」といいます。）、利用の停止、消去又は第三者提供の停止（以下「利用停止等」といいます。）を求められたときは、本人ご自身からのご請求であることを確認の上で、個人情報保護法に従って対応いたします。なお、個人情報及び第三者提供記録の開示につきましては、手数料（1件あたり●1000円）を頂戴しておりますので、あらかじめ御了承ください。</p>


  <h3 class="article-title">7. お問い合わせ</h3>
  <p>開示、訂正等又は利用停止等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。</p>
  <p>個人情報取扱事業者の名称、住所及び代表者氏名</p>
  <p>〒● 〒102-0083 Tokyo, Chiyoda City, Kojimachi, 1 Chome-4 番地 半蔵門ファーストビル 3F</p>
  <p>株式会社●Merito（代表取締役: POSSIN, Brandon ●）</p>
  <p>問い合わせ窓口</p>
  <p>E-mail: ● contact@merito.network</p>
  

  <h3 class="article-title">8. 継続的改善</h3>
  <p>当社は、利用者情報その他の個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。</p>


  <p class="closing">【2024年●月●日制定】</p>
</body>
</html>
`;

export default function TermsAndConditions() {
  return (
    <Text as="span">
      <DocumentViewer title={TITLE} content={CONTENT} />
    </Text>
  );
}
